import { Auth } from 'aws-amplify';

export function redirectToLogIn(){

    const{
        userPoolWebClientId,
        oauth
    } = Auth.configure();


    window.location.assign('https://' + oauth.domain
    + '/oauth2/authorize?'
    + 'identity_provider=' + oauth.idp
    + '&redirect_uri=' + oauth.redirectSignIn
    + '&response_type=' + oauth.responseType
    + '&client_id=' + userPoolWebClientId
    + '&' + oauth.scope.join(" "));
}