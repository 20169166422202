import {API, Auth} from "aws-amplify";
import { buildAction, SEND_COLLECTION_CLEARED, SUBMIT_FORM_JOB_PROFILE_TYPE, SUBMIT_CANDIDATE_DATA, SUBMIT_REFERENCE_TYPE, SUBMIT_VERIFIED_FORM, UPDATE_JOB_PROFILES_SEARCH, UPDATE_JOB_FAMILY_FILTER, SEND_COLLECTION_ERROR, SEND_COLLECTION_PROCESSING, SEND_COLLECTION_SUCCESS, SUBMIT_DEFAULT_QUESTIONNAIRE, SEND_COLLECTION_MESSAGE } from '../actions-index'
import { isEmpty } from "lodash";

const apiName = "ReferenceManagement";

// export function jobFamilyFetchData() {
//     return {
//         type: JOB_FAMILY_FETCH_DATA,
//         payload: jobFamilyData
//     }
// }

export function submitFormJobProfile(jobProfile) {
    return {
        type: SUBMIT_FORM_JOB_PROFILE_TYPE,
        payload: jobProfile
    }
}

export function submitDefaultQuestionnaire(value) {
    console.log("inside submitDefaultQuestionnaire action: ", value);
    return {
        type: SUBMIT_DEFAULT_QUESTIONNAIRE,
        payload: value
    }
}

export function submitCandidates(pendingCandidates) {
    return {
        type: SUBMIT_CANDIDATE_DATA,
        payload: pendingCandidates
    }
}

export function submitReferenceType(referenceType) {
    return {
        type: SUBMIT_REFERENCE_TYPE,
        payload: referenceType
    }
}

export function submitVerifiedForm(values) {
    return {
        type: SUBMIT_VERIFIED_FORM,
        payload: values
    }
}

export function updateJobProfilesSearch(query) {
    return {
        type: UPDATE_JOB_PROFILES_SEARCH,
        payload: query
    }
}

export function updateJobFamilyFilter(family) {
    return {
        type: UPDATE_JOB_FAMILY_FILTER,
        payload: family
    }
}

export function sendCollection(collection) {
    return (dispatch) => {
        if(isEmpty(collection)) {
            dispatch(buildAction(SEND_COLLECTION_CLEARED, ''));
            return
        }

        let errorHandler = (error) => {
            console.error(error);
            dispatch(buildAction(SEND_COLLECTION_ERROR, 'error'));
        };
        dispatch(buildAction(SEND_COLLECTION_PROCESSING, 'processing'));
        
        let body = {
            action: "documentSendingRequestToReferences",
            key: "job",
            posting: {
                id: collection.posting.id,
                referenceType: collection.referenceType.toUpperCase(),
                jobDescription: collection.posting.jobDescription,
                jobName: collection.posting.title
            },
            candidates: collection.candidates
        };
        if(body.posting.referenceType === "QUESTIONNAIRE") {
            if(collection.additionalQuestions && collection.additionalQuestions.length > 0) {
                // combine the questions and set questions array
                body.questions = collection.questionnaire.questions.concat(collection.additionalQuestions);
            } else {
                // assign quizId
                body.posting.quizId = collection.questionnaire.quizId;

            }
        }

        API.post(apiName, "/dataaccess", { body: body })
        .then(
            (response) => {
                if (response.status !== 200 || response.status === 401) {
                    if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                        Auth.signOut();
                    }
                }
                if(response.status === 200){
                    dispatch(buildAction(SEND_COLLECTION_SUCCESS, 'success'));
                    if(response.data && response.data.message && (response.data.message.toLowerCase() !== 'success')) {
                        dispatch(buildAction(SEND_COLLECTION_MESSAGE, response.data.message));
                    }
                } else {
                    console.error("Failed POST");
                    dispatch(buildAction(SEND_COLLECTION_ERROR, 'error'));
                }
            },
            errorHandler
        )
        .catch(errorHandler)
    }
}


