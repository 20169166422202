import React from 'react';

const SignOut = (props) => {
    return (
        <h2>
            You have been logged out.
        </h2>
    );
}

export default SignOut;
