import React from "react";
import { CustomInput } from 'reactstrap';
import { API } from 'aws-amplify';
import { OSUButton, Icon, Table } from "osu-react-components";

const SelectableTable = (props) => {
    const { posting, candidates, mobileCandidateData, pendingCandidates, hideSelect, handleChangeValue, referencesIsLoading, referencesHasError, smallDevice, formattedReferences, 
        hideStatus, deactivateActions, getReferencesSendingEmail, setReferencesSendingEmail } = props

    let dataKeys = [{
        key: "id",
        label: "ID",
        width: hideSelect ? 13 : 10
    }, {
        key: "fullName",
        label: "Name",
        width: hideStatus ? 40 : 30
    }, {
        key: deactivateActions ? 'email' : "jsxEmail",
        label: "Email",
        width: 27
    }, {
        key: deactivateActions ? 'completePhoneNumber' : "jsxPhone",
        label: "Phone Number",
        width: 20
    }]

    let smallDeviceKeys = [{
        key: "id",
        label: "ID",
        width: 25
    }, {
        key: "candidateCard",
        label: "Candidate",
        width: hideStatus ? 75 : 65
    }]

    if (!hideSelect) {
        dataKeys = [
            {
                key: "checkbox",
                label: "",
                width: 3
            },
            ...dataKeys
        ]
    }


    if (!hideStatus) {
        dataKeys = [
            ...dataKeys, {
                key: "action_needed",
                label: "Status",
                width: 10
            }
        ]
        smallDeviceKeys = [
            ...smallDeviceKeys,
            {
                key: "action_needed",
                label: "",
                width: 10
            }
        ]
    }

    const standardData = Array.isArray(candidates) ? [...candidates.map((candidate, index) => {
        const statusFields = determineStatusFields(candidate)
        const { disableCheck = false, action_needed = <div /> } = statusFields

        return {
            ...candidate,
            checkbox: !hideSelect && <CustomInput onKeyPress={(e) => { ['Enter', ' '].includes(e.key) && handleChangeValue(candidate); e.stopPropagation() }} disabled={disableCheck} readOnly onClick={(e) => { e.stopPropagation() }} id={`${candidate.id}checkbox${index}`} type="checkbox" checked={!!pendingCandidates && Array.isArray(pendingCandidates) && pendingCandidates.map(candidate => candidate.id).includes(candidate.id)} />,
            disableCheck,
            action_needed,
            expandedContent: createExpandedContent({ candidate, posting, formattedReferences, smallDevice, hideStatus, referencesIsLoading, referencesHasError, getReferencesSendingEmail, setReferencesSendingEmail }),
            onRowClick: !hideSelect && !disableCheck ? () => handleChangeValue(candidate) : null
        }
    })] : []

    return <Table hover data={smallDevice ? mobileCandidateData(standardData) : standardData} dataKeys={smallDevice ? smallDeviceKeys : dataKeys} expandable={candidates && candidates.length > 0}
    />
}

export default SelectableTable;

const createExpandedContent = (values) => {
    const { candidate, posting, formattedReferences, smallDevice, hideStatus, referencesIsLoading, referencesHasError, getReferencesSendingEmail, setReferencesSendingEmail } = values
    //const { references } = candidate;
    const renderableReference = formattedReferences(candidate.id)
    if (!!referencesIsLoading || !!referencesHasError) {
        return <Table data={renderableReference} dataKeys={[{ key: 'fetchingStatus', width: 100 }]} noHeader nested />
    }
    let dataKeys = [{
        key: "placeholder-no-content",
        width: 10
    }, {
        key: "Name",
        width: hideStatus ? 40 : 30
    }, {
        key: "email",
        width: 25
    }, {
        key: "phone",
        width: 25
    }]

    let mobileKeys = [{
        key: "placeholder-no-content",
        width: 10
    }, {
        key: "Name",
        width: hideStatus ? 90 : 60
    }]

    if (!hideStatus) {
        dataKeys = [
            ...dataKeys, {
                key: "button",
                width: 10
            }
        ]
        mobileKeys = [
            ...mobileKeys, {
                key: "button",
                width: 30
            }
        ]
    }

    const convertButton = (values) => {
        const { id, status = '', sendAction, candidateName, referenceName } = values
        const verify = () =>  !!status && !!status.trim() && typeof status === 'string' && status.toLowerCase().includes('error') ? 'Error' : ''

        switch (status) {
            case 'SENDING':
                return 'Sending';
            case 'SENT':
                return <OSUButton ariaLabel={`Resend e-mail to reference ${referenceName} for candidate ${candidateName}`} disabled={!id} onClick={sendAction} outline>Resend</OSUButton>;
            case 'DECLINED':
                return 'Declined';
            case 'COMPLETED':
                return 'Completed';
            case 'ERROR':
                return 'Error';
            case 'NEW':
                return <OSUButton ariaLabel={`Send e-mail to reference ${referenceName} for candidate ${candidateName}`} disabled={!id} onClick={sendAction} outline>Send</OSUButton>;
            default:
                return verify();
        }
    }

    const sendReferenceEmail = (postingId, candidateName, reference, jobName) => {
        const apiName = "ReferenceManagement";
        const path = "/sendReferenceEmail";
        const init = { 
            body: {
                key: "job",
                pk: postingId, 
                sk: reference.sk,
                name: reference.name,
                email: reference.email,
                phone: reference.phone,
                candidateName,
                jobName: jobName
            }, 
            response: true 
        };
        console.log("sendEmail Post: ", init);
        return API.post(apiName, path, init);
    }

    const data = renderableReference.map(ref => {
        let id = ref.sk;
        let status = ref.data;
        if(getReferencesSendingEmail().includes(id)) {
            status = 'SENDING';
        }
        let sendAction = () => {
            setReferencesSendingEmail([...getReferencesSendingEmail(), id]);
            sendReferenceEmail(posting.id, candidate.fullName, ref, posting.title);
        };
        return {
            ...ref,
            button: convertButton({id, status, sendAction, candidateName: candidate.fullName, referenceName: ref.name })
        }
    })

    return <Table data={data} dataKeys={smallDevice ? mobileKeys : dataKeys} noHeader nested noDataMessage='No references found.' />
}

const determineStatusFields = (candidate) => {

    switch (candidate.status) {
        case "COMPLETED":
            return {
                disableCheck: true,
                action_needed: <Icon type="check" color="green" />
            }
        case "ERROR":
            return {
                disableCheck: true,
                action_needed: <Icon type="exclamation-triangle" color="red"></Icon>
            }
        case "ACTION_NEEDED":
            return {
                disableCheck: false,
                action_needed: null
            }
        case "NEW":
            return {
                disableCheck: false
            }
        /* case "IN_PROGRESS":
            return {
                disableCheck: true
            } */
        default:
            return {
                disableCheck: true
            }
    }
}