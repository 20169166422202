import React, { Component } from 'react';
import '../Header.css';
import { Icon, OSUButton, Overline, Body2 } from 'osu-react-components';
import { isEmpty } from 'lodash';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';
import PropTypes from "prop-types";

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggle() {
        this.setState((state, props) => ({
            isOpen: !state.isOpen
        }));

    }

    render() {
        const { user, handleLogin, handleLogOut, pathName = "", admin, roles } = this.props
        const active = (path = "") => pathName.indexOf(`/${path}`) === 0 ? true : false
        const determineColor = (path = "") => active(path) ? "black" : "gray"
        const determineVariant = (path = "") => active(path) ? "subtitle2" : "body2"
        const collapsed = !!this.props.screenSize ? this.props.screenSize < 992 : false
        const mobileMenuOpen = !!this.state.isOpen && !!collapsed
        const navClass = `nav-item${mobileMenuOpen ? ' mt-2' : ''}`
        const userActions = !isEmpty(user) ? <div className={`d-flex flex-row`}>
            <div className="text-right mr-2">
                <Overline color="gray">{roles && typeof roles === 'string' ? roles : Array.isArray(roles) ? roles.map((role, index) => ` ${role}${index !== roles.length - 1 ? ', ' : ''}`) : "No Roles Found"}</Overline>
                <Body2>{user.name}</Body2>
            </div>
            <OSUButton ariaLabel="Sign out" className={`${mobileMenuOpen ? '' : 'h-100'}`} onClick={handleLogOut} color="red"><Icon color="white" type="lock" /> Sign Out</OSUButton>
        </div>
            : <OSUButton ariaLabel="Sign in" className={`${mobileMenuOpen ? '' : 'h-100'}`} onClick={handleLogin} color="red"><Icon color="white" type="unlock" /> Sign In</OSUButton>

        return <div className={`header-wrapper${mobileMenuOpen ? '' : ' align-items-center'}`}>
            <Navbar className={`${mobileMenuOpen ? 'w-100 ' : ''}px-0`} expand="lg" light>
                <NavbarToggler onClick={this.toggle} />
                {mobileMenuOpen ? userActions : null}
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <OSUButton ariaLabel="Navigate to postings" className={navClass} link path="/postings" uppercase={false} color={pathName === '/' ? "black" : determineColor("postings")} variant={pathName === '/' ? "subtitle2" : determineVariant("postings")}>Reference Collection</OSUButton>
                        </NavItem>
                        {!!admin && <NavItem>
                            <OSUButton ariaLabel="Navigate to job profiles" className={navClass} link path="/job-profiles" uppercase={false} color={determineColor("job-profiles")} variant={determineVariant("job-profiles")}>Job Profiles</OSUButton>
                        </NavItem>}
                        {!!admin && <NavItem>
                            <OSUButton ariaLabel="Navigate to default questionnaire" className={navClass} link path="/default-questionnaire" uppercase={false} color={determineColor("default-questionnaire")} variant={determineVariant("default-questionnaire")}>Default Questionnaire</OSUButton>
                        </NavItem>}
                        {!!admin && <NavItem>
                            <OSUButton ariaLabel="Navigate to questions" className={navClass} link path="/questions" uppercase={false} color={determineColor("questions")} variant={determineVariant("questions")}>Questions</OSUButton>
                        </NavItem>}
                    </Nav>
                </Collapse>
            </Navbar>
            {!mobileMenuOpen ? userActions : null}
        </div>
    }
}

export default Header

Header.defaultProps = {
    admin: false
}

Header.propTypes = {
    admin: PropTypes.bool,
}
