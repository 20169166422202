import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PostingItem from '../components/Item';
import { updateBreadcrumbTrail, candidatesFetchData, postingItemFetchData, submitCandidates, submitReferenceType } from '../../actions-index';
// import { submitCandidates } from '../../actions/collection';
// import { referencesFetchData } from '../../actions/references'

const mapStateToProps = (state) => {
    return {
        candidates: state.candidates,
        references: state.references,
        posting: state.postingItem,
        postingIsLoading: state.postingIsLoading,
        postingHasError: state.postingHasError,
        pendingCandidates: state.pendingCandidates
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        fetchCandidates: (postingId) => dispatch(candidatesFetchData(postingId)),
        fetchPostingItem: (postingId) => dispatch(postingItemFetchData(postingId)),
        // fetchReferences: () => dispatch(referencesFetchData())
        submitCandidates: (candidates) => dispatch(submitCandidates(candidates)),
        updateReferenceType: (referenceType) => dispatch(submitReferenceType(referenceType))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostingItem));