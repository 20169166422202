import { API, Auth } from "aws-amplify";
import hash from "object-hash";
import {
    buildAction, QUESTIONS, QUESTIONS_ERROR, QUESTIONS_LOADING, QUESTION_SEARCH_QUERY,
    SUBMIT_QUESTION_DUPLICATE, SUBMIT_QUESTION_ERROR, SUBMIT_QUESTION_PROCESSING, SUBMIT_QUESTION_SUCCESS
} from '../actions-index';
import * as _ from "lodash";

const apiName = "ReferenceManagement";
const application = "REFERENCE";

export function findQuestions() {
    return (dispatch) => {
        dispatch(buildAction(QUESTIONS_ERROR, false));  // reset from previous requests
        let errorHandler = (error) => {
            console.error(error);
            dispatch(buildAction(QUESTIONS_ERROR, true));
        };
        dispatch(buildAction(QUESTIONS_LOADING, true));
        API.get(apiName, `/getallquestions/${application}`)
            .then(
                (response) => {
                    if ((response.httpStatusCode !== undefined && (response.httpStatusCode !== 200 || response.httpStatusCode === 401)) || response.response !== undefined) {
                        if (response.httpStatusCode === 401 || (response.response !== undefined && response.response.status === 401)) {
                            Auth.signOut();
                        }
                    } else {
                        let questions = response.data.questions;
                        if (questions.length > 0) {
                            questions = _.sortBy(questions, question => {
                                return question.title.toLowerCase();
                            }); // sort by title
                        }
                        dispatch(buildAction(QUESTIONS, questions));
                    }
                },
                errorHandler
            )
            .catch(errorHandler)
            .finally(() => dispatch(buildAction(QUESTIONS_LOADING, false)));
    }
}

export function setQuestionSearchQuery(searchQuery) {
    return (dispatch) => {
        dispatch(buildAction(QUESTION_SEARCH_QUERY, searchQuery));
    }
}

export function submitQuestion(question) {
    return (dispatch) => {
        // reset flags from prior requests (if necessary)
        dispatch(buildAction(SUBMIT_QUESTION_DUPLICATE, false));
        dispatch(buildAction(SUBMIT_QUESTION_ERROR, false));

        let errorHandler = (error) => {
            console.error(error);
            dispatch(buildAction(SUBMIT_QUESTION_ERROR, true));
            dispatch(buildAction(SUBMIT_QUESTION_PROCESSING, false));
        };

        const questionId = hash({
            application: question.application,
            title: question.title.trim(),
            questionType: question.questionType.trim(),
            idType: (question.idType !== undefined && question.idType.trim() !== '' ? question.idType.trim() : "QUESTION")
        }); // build a new question id (hash) (Note: This must match how the hash is created in the QuestionAnswerBank lambda in the content-publish-api project)

        dispatch(buildAction(SUBMIT_QUESTION_PROCESSING, true));
        API.get(apiName, `/getquestion/${application}/${questionId}`) // determine whether a question with that hash already exists
            .then(
                (response) => {
                    if (response.httpStatusCode !== 200 || response.httpStatusCode === 401) {
                        if (response.httpStatusCode === 401 || (response.response !== undefined && response.response.status === 401)) {
                            Auth.signOut();
                        }
                    }

                    const existingQuestion = response.data && response.data.question ? response.data.question : null;
                    const existingOnCreate = !question.id && !!existingQuestion // create question - hash matches an existing question
                    const existingOnEdit = !!question.id && !!existingQuestion && existingQuestion.hasOwnProperty('id') && existingQuestion.id !== question.id // edit question - new hash matches an existing question

                    if (existingOnCreate || existingOnEdit) {
                        dispatch(buildAction(SUBMIT_QUESTION_DUPLICATE, true));
                        dispatch(buildAction(SUBMIT_QUESTION_PROCESSING, false));
                    } else {
                        API.post(apiName, "/createquestion", { body: question })
                            .then(
                                (response) => {
                                    if (response.httpStatusCode !== 200 || response.httpStatusCode === 401) {
                                        if (response.httpStatusCode === 401 || (response.response !== undefined && response.response.status === 401)) {
                                            Auth.signOut();
                                        }
                                    }

                                    if (response.data.questions.length > 0) {
                                        dispatch(buildAction(SUBMIT_QUESTION_SUCCESS, true));
                                    } else { // this shouldn't happen, but log and handle it in case it does
                                        if (response.data.failedQuestions.length > 0) {
                                            console.error("Failed Question", response.data.failedQuestions[0]);
                                        }
                                        if (response.data.invalidQuestions.length > 0) {
                                            console.error("Invalid Question", response.data.invalidQuestions[0]);
                                        }
                                        dispatch(buildAction(SUBMIT_QUESTION_ERROR, true));
                                    }
                                    dispatch(buildAction(SUBMIT_QUESTION_PROCESSING, false))
                                },
                                errorHandler
                            )
                            .catch(errorHandler);
                    }
                },
                errorHandler
            )
            .catch(errorHandler);
    }
}

export function resetSubmitQuestionFlags() {
    return (dispatch) => {
        dispatch(buildAction(SUBMIT_QUESTION_DUPLICATE, false));
        dispatch(buildAction(SUBMIT_QUESTION_ERROR, false));
        dispatch(buildAction(SUBMIT_QUESTION_SUCCESS, false));
    }
}