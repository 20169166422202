import { CANDIDATES_FETCH_DATA, UPDATE_CANDIDATES_SEARCH, CANDIDATES_IS_LOADING, CANDIDATES_HAS_ERROR } from '../actions-index';

export function candidatesIsLoading(state = false, action = {}) {
    switch (action.type) {
        case CANDIDATES_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function candidatesHasError(state = false, action = {}) {
    switch (action.type) {
        case CANDIDATES_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }
}

export function candidates(state = [], action = {}) {
    switch (action.type) {
        case CANDIDATES_FETCH_DATA:
            return action.payload;
        default:
            return state;
    }
}

export function candidatesSearchQuery(state = "", action = {}) {
    switch (action.type) {
        case UPDATE_CANDIDATES_SEARCH:
            return action.payload;
        default:
            return state;
    }
}
