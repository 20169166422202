
import React from "react";
import { Body1, Table } from "osu-react-components";

const ViewOnlyTable = (props) => {
    const { posting, candidates = [], mobileCandidateData, smallDevice = false, formattedReferences, referencesIsLoading, referencesHasError } = props
    const dataKeys = [{
        key: "id",
        label: "ID",
        width: 10
    }, {
        key: "fullName",
        label: "Name",
        width: 35
    }, {
        key: "jsxEmail",
        label: "Email",
        width: 35
    }, {
        key: "jsxPhone",
        label: "Phone Number",
        width: 20
    }]

    const smallDeviceKeys = [{
        key: "id",
        label: "ID",
        width: 25
    }, {
        key: "candidateCard",
        label: "Candidate",
        width: 75
    }]

    const standardData = [...candidates.map(candidate => {
        return {
            ...candidate,
            expandedContent: createExpandedContent({ candidate, posting, formattedReferences, smallDevice, referencesIsLoading, referencesHasError })
        }
    })]

    return <Table hover={false}  data={smallDevice ? mobileCandidateData(standardData) : standardData} dataKeys={smallDevice ? smallDeviceKeys : dataKeys}
        expandable={candidates && candidates.length > 0}
    />
}

const createExpandedContent = (values) => {
    const { candidate, formattedReferences, referencesIsLoading, referencesHasError, smallDevice = false } = values
    //const { references } = candidate;
    const referenceList = formattedReferences(candidate.id)

    if (!!referencesIsLoading || !!referencesHasError) {
        return <Table data={referenceList} dataKeys={[{ key: 'fetchingStatus', width: 100 }]} noHeader nested />
    }

    const dataKeys = [{
        key: "placeholder-no-content",
        width: 10
    }, {
        key: "Name",
        width: 30
    }, {
        key: "email",
        width: 30
    }, {
        key: "phone",
        width: 30
    }]


    const mobileKeys = [{
        key: "placeholder-no-content",
        width: 10
    }, {
        key: "Name",
        width: 45
    }, {
        key: 'contactInfo',
        width: 45
    }]

    return <Table data={smallDevice ? referenceList.map(ref => {
        const { email, phone } = ref

        return {
            ...ref,
            contactInfo: <div className="d-flex flex-column flex-md-row">
                {email && <Body1>{email}</Body1>}
                {phone && <Body1 className="ml-md-4">{phone}</Body1>}
            </div>
        }
    }) : referenceList}
        dataKeys={smallDevice ? mobileKeys : dataKeys} noDataMessage='No references found.' noHeader nested />
}

export default ViewOnlyTable
