import React, { Component } from "react";
import ViewOnlyTable from "./ViewOnlyTable";
import SelectableTable from './SelectableTable';
import { OSULoading, OSUError, Icon, OSUButton, Typography, Body1, Heading6, PaginationWrapper } from 'osu-react-components';
import { omit, intersectionBy, find, chunk } from 'lodash';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

class Candidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateId: null,
            referencesSendingEmail: [],
            dataIndex: 0
        }
    }

    componentDidMount() {
        if (!!this.props.posting && !!this.props.posting.id) {
            this.props.fetchCandidates(this.props.posting.id)
        }
    }

    getReferencesSendingEmail() {
        return this.state.referencesSendingEmail;
    }

    setReferencesSendingEmail(referencesSendingEmail) {
        this.setState({ referencesSendingEmail });
    }

    setDataIndex = (dataIndex) => {
        this.setState({ dataIndex });
    }

    searchCandidates(query) {
        this.props.updateSearch(query)
    }

    fetchReferences({ candidateId }) {
        this.props.fetchReferences(this.props.posting.id, candidateId);
        this.setReferencesSendingEmail([]);
    }

    handleChangeValue = (input) => {
        const { pendingCandidates = [], candidates = [] } = this.props
        if(!input) {
            return
        }
        const targetValue = typeof input === 'string' ? JSON.parse(input) : input
        const preparedVal = !Array.isArray(targetValue) ? find(candidates, (o) => o.id === targetValue.id) : intersectionBy(candidates, pendingCandidates, 'id')

        if (Array.isArray(preparedVal)) {
            if (preparedVal.length === pendingCandidates.length) {
                this.props.submitCandidates([])
            } else {
                this.props.submitCandidates(preparedVal.filter(val => !val.hasOwnProperty('disableCheck') || !val.disableCheck))
            }
        } else if (pendingCandidates.filter(val => val.id === preparedVal.id).length > 0) {
            this.props.submitCandidates(pendingCandidates.filter(val => val.id !== preparedVal.id))
        } else if (pendingCandidates.length > 0) {
            this.props.submitCandidates([...pendingCandidates, preparedVal])
        } else {
            this.props.submitCandidates([preparedVal])
        }
    };

    render() {
        const { state, props, setDataIndex } = this
        const { candidates = [], candidatesIsLoading, candidatesHasError, deactivateActions, isSearchable, searchQuery } = props
        const { dataIndex } = state
        let candidatesList = candidates

        if (candidatesIsLoading) {
            return <OSULoading className="mt-2" text="Loading Candidates..." />
        }
        if (candidatesHasError) {
            return <OSUError ariaLabel="Reload candidates" text="Unable to load candidates at this time." actionText='Reload' onClick={() => this.props.fetchCandidates(this.props.posting.id)} />
        }
        if (!Array.isArray(candidates)) {
            return <Heading6 className="mt-2">Unable to find candidates for this position.</Heading6>
        }
        const smallDevice = !!this.props.screenSize && this.props.screenSize < 768
        //const xSmallDevice = !!this.props.screenSize && this.props.screenSize < 576

        if (!!isSearchable && !!searchQuery && searchQuery.length > 0) {
            const testString = (str = '') => str.toLowerCase().includes(searchQuery.toLowerCase())
            candidatesList = candidates.filter(candidate => testString(candidate.id) || testString(candidate.email) || testString(candidate.fullName))
        }

        const formattedReferences = (candidateId) => {
            const { activeReferences, posting, candidates, referencesIsLoading, referencesHasError } = this.props;
            if (!!referencesIsLoading || !!referencesHasError) {
                return [{
                    fetchingStatus: !!referencesIsLoading ? <OSULoading text="Loading References..." /> :
                        <OSUError ariaLabel={`Reload references for candidate ${candidateId}`} small text={`We had trouble loading references for ${candidateId}.`} onClick={() => this.fetchReferences({ candidateId })} />
                }]
            }

            const candId = candidateId === activeReferences.candidateId ? candidateId : null;
            const { references } = activeReferences;
            if (!references || references.length < 1 || !candId) {
                return []
            }
            const matchingCandidates = candidates.filter(candidate => !!candId && candidate.id === candId)
            const candidate = Array.isArray(matchingCandidates) && matchingCandidates.length > 0 ? matchingCandidates[0] : null
            if (!matchingCandidates || !candidate) {
                return []
            }

            return references.map(ref => {
                const state = { reference: ref, posting, candidate: omit(candidate, ['jsxPhone', 'jsxEmail', 'candidateCard']) }

                return {
                    ...ref,
                    Name: <OSUButton ariaLabel={`Navigate to reference view for ${ref.name}`} path={{ pathname: `/postings/${posting.id}/candidate/${candidate.id}/references/${ref.sk}`, state }} link uppercase={false} variant={'subtitle1'}>{ref.name}</OSUButton>,
                }
            })
        }

        const formatCandidates = () => {
            return [...candidatesList.map(candidate => {
                const { email, firstName, lastName } = candidate
                const phone = candidate.completePhoneNumber

                return {
                    ...candidate,
                    fullName: `${firstName} ${lastName}`,
                    jsxEmail: !!email ? <a href={`mailto:${email}`} ><Typography variant={smallDevice ? 'body2' : 'body1'} className="mb-1">
                        {email}
                    </Typography></a> : '',
                    jsxPhone: !!phone ? <a href={`tel:+${phone.replace(/\D/g, '')}`} ><Typography variant={smallDevice ? 'body2' : 'body1'} className="mb-1">
                        {phone}
                    </Typography></a> : '',
                    innerExpandedAction: this.fetchReferences.bind(this, { candidateId: candidate.id }),
                }
            })]
        }

        const mobileCandidateData = (candidates) => candidates.map(candidate => {
            const email = deactivateActions ? candidate.email : candidate.jsxEmail
            const phone = deactivateActions ? candidate.completePhoneNumber : candidate.jsxPhone

            return {
                ...candidate,
                candidateCard: <div>
                    <div className="d-flex flex-column flex-sm-row">
                        {email}
                        {phone}
                    </div>
                    <Body1>{candidate.fullName}</Body1>
                </div>,
            }
        })

        const chunkedData = candidatesList.length > 0 ? chunk(formatCandidates(), 10) : []

        const tableToRender = this.props.viewOnly
            ? <ViewOnlyTable {...this.props} handleChangeValue={this.handleChangeValue} smallDevice={smallDevice || false} candidates={chunkedData[dataIndex]} mobileCandidateData={mobileCandidateData} formattedReferences={formattedReferences} />
            : <SelectableTable {...this.props} handleChangeValue={this.handleChangeValue} smallDevice={smallDevice || false} candidates={chunkedData[dataIndex]} mobileCandidateData={mobileCandidateData} formattedReferences={formattedReferences} 
            getReferencesSendingEmail={this.getReferencesSendingEmail.bind(this)} setReferencesSendingEmail={this.setReferencesSendingEmail.bind(this)} />

        return <div>
            {!!isSearchable && <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <Icon type="search" color="gray" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input type="text" placeholder='Search candidates...' value={searchQuery} onChange={(e) => this.searchCandidates(e.target.value)} />
            </InputGroup>}
            <PaginationWrapper totalPageCount={chunkedData.length} updateDataIndex={setDataIndex} dataIndex={dataIndex}>
                {tableToRender}
            </PaginationWrapper>
        </div>

    }
}

export default Candidates


Candidates.defaultProps = {
    smallDevice: false,
    hideStatus: false,
    deactivateActions: false,
    isSearchable: false
}