/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "ReferenceManagement",
            "endpoint": "https://zbk72ftb64.execute-api.us-east-2.amazonaws.com/develop",
            "region": "us-east-2"
        }
    ],
    "aws_content_delivery_bucket": "reference-management-20190124085812-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3hzcn8dzrublw.cloudfront.net"
};


export default awsmobile;
