import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { findJobProfiles, submitJobProfileQuestionnaire, resetSubmitJobProfileQuestionnaireFlags, updateBreadcrumbTrail } from '../../actions-index';
import { findQuestions } from '../../Questions/actions';
import Questionnaire from '../components/Questionnaire';

const mapStateToProps = (state, props) => {
    return {
        jobProfiles: state.jobProfiles,
        jobProfilesError: state.jobProfilesError,
        jobProfilesLoading: state.jobProfilesLoading,
        questions: state.questions,
        questionsError: state.questionsError,
        questionsLoading: state.questionsLoading,
        submitJobProfileQuestionnaireSuccess: state.submitJobProfileQuestionnaireSuccess,
        submitJobProfileQuestionnaireProcessing: state.submitJobProfileQuestionnaireProcessing,
        submitJobProfileQuestionnaireError: state.submitJobProfileQuestionnaireError,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findJobProfiles: (id) => dispatch(findJobProfiles(id)),
        findQuestions: () => dispatch(findQuestions()),
        submitJobProfileQuestionnaire: (jobProfileId, questionnaire) => dispatch(submitJobProfileQuestionnaire(jobProfileId, questionnaire)),
        resetSubmitJobProfileQuestionnaireFlags: () => dispatch(resetSubmitJobProfileQuestionnaireFlags()),
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questionnaire));
