import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { findQuestions, setQuestionSearchQuery } from '../actions';
import QuestionsList from "../components/List";

const mapStateToProps = (state, props) => {
    return {
        questions: state.questions,
        questionsError: state.questionsError,
        questionsLoading: state.questionsLoading,
        questionSearchQuery: state.questionSearchQuery,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findQuestions: () => dispatch(findQuestions()),
        setQuestionSearchQuery: (searchQuery) => dispatch(setQuestionSearchQuery(searchQuery))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsList));
