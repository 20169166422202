import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { redirectToLogIn } from '../../util/util';
import { OSUError, OSULoading } from 'osu-react-components';

class SignIn extends Component {

    state = {
        isSigningIn: true,
        signInFailed: false,
    }

    handleLoginClick = () => {
        redirectToLogIn();
    }

    componentDidMount() {
        Auth.currentSession()
            .then((session) => {
                this.props.setUser(session.idToken.payload);
                this.setState({
                    isSigningIn: false
                })
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    Auth.currentSession()
                        .then((session) => {
                            console.log(error);
                            this.props.setUser(session.idToken.payload);
                            this.setState({
                                isSigningIn: false
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                            setTimeout(() => {
                                Auth.currentSession()
                                    .then((session) => {
                                        this.props.setUser(session.idToken.payload);
                                        this.setState({
                                            isSigningIn: false
                                        })

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            isSigningIn: false,
                                            signInFailed: true
                                        })
                                    });
                            }, 3000);//second wait
                        });
                }, 1000);//first wait

            });
    }

    render() {
        if (this.state.isSigningIn) {
            return <OSULoading text="Logging in..." />
        } else if (this.state.signInFailed) {
            return <OSUError ariaLabel="Sign in" text="You could not be logged in. Please attempt to sign in again." actionText='Sign In' onClick={this.handleLoginClick} />
        }
        return <Redirect to="/" />
    }
}

export default SignIn;