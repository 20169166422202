import React, { Fragment } from 'react';
import { OSULoading, OSUError, Table, OSUButton, Heading6, Subtitle1, Body2, Subtitle2, Caption, Body1, Heading4 } from 'osu-react-components';
import { isEmpty, capitalize } from 'lodash';

class SendCollection extends React.Component {
    constructor() {
        super();
        this.sendCollection = this.sendCollection.bind(this)
    }

    componentDidMount() {
        const postingId = this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') && this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null
        if (!this.props.hasOwnProperty('disableBreadcrumbs') || !this.props.disableBreadcrumbs) {
            this.props.updateBreadcrumbTrail([
                {
                    path: '/',
                    text: 'postings'
                },
                {
                    path: `/postings/${postingId}`,
                    text: postingId
                },
                {
                    text: 'Edit Collection',
                    active: true
                }
            ])
        }
    }

    componentWillUnmount() {
        if(!!this.props.collectionConfirmation) {
            !!this.props.clearData && this.props.clearData();
        }
    }

    sendCollection() {
        const { additionalQuestions, questionnaire } = this.props.collection.verifiedForm
        const { candidates, referenceType } = this.props.collection
        const posting = this.props.posting

        if ((referenceType === "QUESTIONNAIRE" && !isEmpty(questionnaire)) || referenceType === "LETTER") {
            this.props.sendCollection({ additionalQuestions, questionnaire, candidates, referenceType, posting })
        }
    }

    render() {
        const { collectionIsLoading, collectionHasError, collectionConfirmation, collectionMessage } = this.props
        const postingId = !!this.props.posting && !!this.props.posting.id ? this.props.posting.id : this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') && this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null
        const smallDevice = !!this.props.screenSize && this.props.screenSize < 768;

        if (!!collectionConfirmation) {
            return <div>
                <Heading6 className="mt-4 mb-2">Collection Confirmation</Heading6>
                <hr />
                {collectionMessage && <Fragment>
                    <Body2>
                        {collectionMessage}
                    </Body2>
                    <hr />
                </Fragment>}
                <Body2>
                    You have sent the selected candidates references an email with a link to complete the reference collection. Please click the button below or go back to the job posting page in the reference collection section to see the status of the candidates reference completetion.
                </Body2>
                <hr />
                <OSUButton ariaLabel={`Go to job posting ${postingId}`} outline uppercase color="gray" path={`/postings/${postingId}`}>Go to Job Posting</OSUButton>
            </div>
        } else if(!!collectionIsLoading) {
            return <OSULoading text="Sending for Collection..."/>
        } else if(!!collectionHasError) {
            return <OSUError ariaLabel={`Retry sending collection`} text="We could not send your collection. Please try again." actionText='Retry' onClick={this.sendCollection}/>
        }
        const updatingCollection = this.props.hasOwnProperty('location') && this.props.location.hasOwnProperty('state') && this.props.location.state.hasOwnProperty('collection')
        const collection = updatingCollection
            ? this.props.location.state.collection
            : !isEmpty(this.props.collection)
                ? this.props.collection
                : null
        if (!collection) {
            return <Heading6>
                Something went wrong. Please return home and try again.
            </Heading6>
        }
        const { candidates = [], referenceType = "" } = collection
        const invalidForm = !collection.verifiedForm || isEmpty(collection.verifiedForm) || !collection.verifiedForm.hasOwnProperty('questionnaire') || isEmpty(collection.verifiedForm.questionnaire)
        const disableSend = (referenceType === "QUESTIONNAIRE" && (isEmpty(this.props.collection.verifiedForm.questionnaire) || invalidForm)) || candidates.length < 1
        const disableUpdating = candidates.length < 1 && (referenceType !== 'QUESTIONNAIRE' || referenceType !== 'LETTER')
        const dataKeys = [{
            key: "id",
            width: 10
        }, {
            key: "fullName",
            width: 30
        }, {
            key: "email",
            width: 30
        }, {
            key: "completePhoneNumber",
            width: 30
        }]

        const smallDeviceKeys = [{
            key: "id",
            label: "ID",
            width: 25
        }, {
            key: "candidateCard",
            label: "Candidate",
            width: 75
        }]

        const mobileCandidateData = candidates.map(candidate => {
            const email = candidate.email
            const phone = candidate.completePhoneNumber

            return {
                ...candidate,
                candidateCard: <div>
                    <div className="d-flex">
                        <Caption color="gray">{email}</Caption>
                        <Caption className="ml-4" color="gray">{phone}</Caption>
                    </div>
                    <Body1>{candidate.fullName}</Body1>
                </div >
            }
        })

        return <div>
            {updatingCollection && <Heading4 dataTestId='pageHeading' className="mt-3">Send for Collection</Heading4>}
            <hr />
            <Subtitle2>
                Reference Type:
            </Subtitle2>
            <Subtitle1 className="text-capitalize">
                {capitalize(referenceType)}
            </Subtitle1>
            <hr />
            <Subtitle2>
                Selected Candidates:
            </Subtitle2>
            <Table data={smallDevice ? mobileCandidateData : candidates} dataKeys={smallDevice ? smallDeviceKeys : dataKeys} hover={false} noHeader />
            <Heading6 className="mt-4 mb-2">Send for Collection</Heading6>
            <Subtitle1 className="mb-2">The collection process will send a notification to all references associated with the selected candidates to complete a {collection.referenceType || 'submission'} within 14 days. This reference type cannot be changed once sent.</Subtitle1>
            {!updatingCollection && !!this.props.previousButton && this.props.previousButton()}
            <OSUButton ariaLabel="Finish process and send for collection" disabled={updatingCollection ? disableUpdating : disableSend} onClick={this.sendCollection}>Send</OSUButton>
        </div >
    }
}

export default SendCollection;