import {
    CANDIDATES_FETCH_DATA,
    UPDATE_CANDIDATES_SEARCH,
    CANDIDATES_IS_LOADING,
    CANDIDATES_HAS_ERROR,
} from '../actions-index';
import {API, Auth} from 'aws-amplify';

export function candidatesFetchData(postingId) {
    let apiGW = { name: 'ReferenceManagement', path: `/job/${postingId}/candidates?includeReferences=true`, params: { response: true } };

    return function (dispatch) {
        dispatch(candidatesHasError(false))
        dispatch(candidatesIsLoading(true))
        return API.get(apiGW.name, apiGW.path, apiGW.params)
            .then(
                (response) => {
                    if (response.status !== 200 || response.status === 401) {
                        if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                            Auth.signOut();
                        }
                    }else {
                        // console.log('ersponse', response)
                        dispatch(receivedCandidates(response.data));
                    }
                }
            )
            .catch(error => {
                console.log('Error fetching candidates', error);
                dispatch(candidatesHasError(true))
            }).finally(() => dispatch(candidatesIsLoading(false)))
    }
}

export function candidatesIsLoading(loading) {
    return {
        type: CANDIDATES_IS_LOADING,
        payload: loading
    };
}

export function candidatesHasError(error) {
    return {
        type: CANDIDATES_HAS_ERROR,
        payload: error
    };
}

export function receivedCandidates(candidates) {
    return {
        type: CANDIDATES_FETCH_DATA,
        payload: candidates.map(candidate => {
            const { firstName, lastName } = candidate
            return {
                ...candidate,
                fullName: `${firstName} ${lastName}`,
            }
        })
    }
}

export function updateCandidatesSearch(query) {
    return {
        type: UPDATE_CANDIDATES_SEARCH,
        payload: query
    }
}