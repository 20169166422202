import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SendCollection from '../components/SendCollection';
import { updateBreadcrumbTrail, submitCandidates, submitReferenceType, submitFormJobProfile, updateJobProfilesSearch, sendCollection } from '../../actions-index';

const mapStateToProps = (state) => {
    return {
        collection: {
            candidates: state.pendingCandidates,
            referenceType: state.pendingReferenceType,
            jobProfile: state.pendingFormJobProfile,
            verifiedForm: state.verifiedForm
        },
        collectionIsLoading: state.collectionStatus === 'processing',
        collectionHasError: state.collectionStatus === 'error',
        collectionConfirmation: state.collectionStatus === 'success',
        collectionMessage: (state.collectionMessage || '')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        submitCandidates: (candidates) => dispatch(submitCandidates(candidates)),
        updateReferenceType: (referenceType) => dispatch(submitReferenceType(referenceType)),
        updateFormJobProfile: (jobProfile) => dispatch(submitFormJobProfile(jobProfile)),
        updateSearch: (query) => dispatch(updateJobProfilesSearch(query)),
        sendCollection: (collection) => dispatch(sendCollection(collection))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendCollection));