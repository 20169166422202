import React, { Component } from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import '../postings.css';
import { Table, Heading4, Icon, OSUError, PaginationWrapper } from 'osu-react-components';
import { OSULoading } from 'osu-react-components';
import * as _ from "lodash";

const dataKeys = [{
    key: "id",
    label: "ID",
    width: 10
}, {
    key: "title",
    label: "Position Title",
    width: 50
}, {
    key: "primaryRecruiter",
    label: "Assigned Recruiter",
    width: 40
}]


const mobileKeys = [{
    key: "id",
    label: "ID",
    width: 20
}, {
    key: "title",
    label: "Position Title",
    width: 80
}]

class PostingsList extends Component {
    constructor() {
        super();
        this.state = {
            redirectToTriggered: false,
            redirectPath: '/',
            pageTitle: 'Postings',
            postingDataPageIndex: 0
        }
        this.searchPostings = this.searchPostings.bind(this)
        this.navigateToPosting = this.navigateToPosting.bind(this)
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.pageContent.focus();
        this.props.fetchPostings();
    }

    searchPostings(query) {
        this.props.updateSearch(query)
        // reset page counts
    }

    navigateToPosting(postingId) {
        this.props.history.push(`/postings/${postingId}`)
    }

    updatePostingDataPageIndex = (pageIndex) => {
        this.setState({
            postingDataPageIndex: pageIndex
        });
    }

    render() {
        let postingDataPageItems = [];
        const mobileView = !!this.props.screenSize && this.props.screenSize < 768
        const { isLoading, searchQuery, postings } = this.props
        let postingData = postings

        if (searchQuery && searchQuery.length > 0) {
            const testString = (str = '') => str.toLowerCase().includes(searchQuery.toLowerCase())
            postingData = postingData.filter(posting => testString(posting.id) || testString(posting.title) || testString(posting.primaryRecruiter))
        }

        // split data into chunks for pagination
        postingData = _.chunk(postingData, 10);

        // set page items to display
        postingDataPageItems = postingData[this.state.postingDataPageIndex] || [];

        const renderPostingData = () => {
            if (this.props.postingsHasError) {
                return (<OSUError ariaLabel="Reload postings" text="Postings cannot be loaded at this time." onClick={() => this.props.fetchPostings()} />);
            } else if (isLoading) {
                return <OSULoading text="Loading Postings..." />
            }
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" placeholder="Search" value={searchQuery} onChange={(e) => this.searchPostings(e.target.value)} />
                </InputGroup>
                <PaginationWrapper totalPageCount={postingData.length} updateDataIndex={this.updatePostingDataPageIndex} dataIndex={this.state.postingDataPageIndex}>
                    {postingData && <Table data={postingDataPageItems.map(posting => {
                        return {
                            ...posting,
                            onRowClick: () => this.navigateToPosting(posting.id)
                        }
                    })}
                        dataKeys={mobileView ? mobileKeys : dataKeys} noDataMessage="No Postings found" />
                    }
                </PaginationWrapper>
            </div>
        }

        return <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageContent">
            <Heading4 id="pageContent" className="mb-2">{this.state.pageTitle}</Heading4>
            {renderPostingData()}
        </div>
    }
}

export default PostingsList