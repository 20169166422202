import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { findQuestions, submitVerifiedForm, findJobProfileQuestionnaire } from '../../actions-index';
import FormReview from "../components/FormReview";

const mapStateToProps = (state) => {
    return {
        questions: state.questions,
        questionsError: state.questionsError,
        questionsLoading: state.questionsLoading,
        jobProfileQuestionnaire: state.jobProfileQuestionnaire,
        jobProfileQuestionnaireError: state.jobProfileQuestionnaireError,
        jobProfileQuestionnaireLoading: state.jobProfileQuestionnaireLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findQuestions: () => dispatch(findQuestions()),
        updateVerifiedForm: (values) => dispatch(submitVerifiedForm(values)),
        findJobProfileQuestionnaire: (jobProfileId) => dispatch(findJobProfileQuestionnaire(jobProfileId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormReview));
