import React, { Component } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText, Label, FormGroup } from "reactstrap";
import { Table, Heading4, OSUButton, PaginationWrapper, Icon } from "osu-react-components";
import * as _ from "lodash";
import { OSUError, OSULoading } from 'osu-react-components';


export default class QuestionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Questions",
            questionDataKeys: [
                { key: "title", label: "Question", width: 80 },
                { key: "type", label: "Type", width: 10 },
                { key: "enabled", label: "Enabled", width: 10 }
            ],
            questionDataPageIndex: 0,
            showDisabled: false
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.pageContent.focus();
        this.findQuestions();
    }

    findQuestions = () => {
        this.props.findQuestions();
    }

    onQuestionSearch = (event) => {
        this.props.setQuestionSearchQuery(event.target.value);
        // reset page counts
    }

    updateQuestionDataPageIndex = (pageIndex) => {
        this.setState({
            questionDataPageIndex: pageIndex
        });
    }

    setDisabled() {
        if (this.state.showDisabled === false) {
            this.setState({
                showDisabled: true
            });
        }
        else {
            this.setState({
                showDisabled: false
            });
        }
    }

    render() {
        console.log("questions: ", this.props.questions)
        let questionData = [];
        let questionDataPageItems = [];
        if (this.props.questions && this.props.questions.length > 0) {
            // build question data
            this.props.questions.forEach(question => {
                let questionDataItem = { ...question }; // avoids adding rowPath to state
                questionDataItem.rowPath = {
                    pathname: "/question",
                    state: {
                        question: question
                    }
                };
                questionDataItem.type = _.capitalize(question.questionType);
                questionDataItem.enabled = question.disabled ? false : <Icon type="check" color="gray" />;

                if(this.state.showDisabled === true) {
                    questionData.push(questionDataItem);
                }
                else {
                    if(question.disabled !== true)
                        questionData.push(questionDataItem);
                }                
            });

            // execute search query (if necessary)
            if (this.props.questionSearchQuery) {
                const matchString = (str = "") => str.toLowerCase().includes(this.props.questionSearchQuery.toLowerCase());
                questionData = questionData.filter(question => {
                    return matchString(question.title) || matchString(question.questionType);
                });
            }

            // split data into chunks for pagination
            questionData = _.chunk(questionData, 8);

            // set page items to display
            questionDataPageItems = questionData[this.state.questionDataPageIndex] || [];
        }

        const renderQuestionData = () => {
            if (this.props.questionsError) {
                return (
                    <OSUError ariaLabel="Reload questions" text="Questions cannot be loaded at this time." onClick={() => this.findQuestions()} />
                );
            } else if (this.props.questionsLoading) {
                return <OSULoading text="Loading Questions..." />
            }

            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="fa fa-search" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" placeholder="Search" value={this.props.questionSearchQuery} onChange={(e) => this.onQuestionSearch(e)} />
                </InputGroup>
                <br />
                <FormGroup check>
                    <Label check for="showDisabled">
                        <Input name="showDisabled" type="checkbox" onClick={(checked) => this.setDisabled(checked)} />
                        Show disabled questions
                    </Label>

                </FormGroup>

                <PaginationWrapper totalPageCount={questionData.length} updateDataIndex={this.updateQuestionDataPageIndex} dataIndex={this.state.questionDataPageIndex}>
                    <Table dataKeys={this.state.questionDataKeys} data={questionDataPageItems}
                        noDataMessage="There are currently no questions" />
                </PaginationWrapper>
            </div>
        }

        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Heading4 id="pageHeader" className="mb-2">
                    {this.state.pageTitle}
                    {!this.props.questionsLoading && !this.props.questionsError &&
                        <div style={{ float: "right" }}>
                            <OSUButton ariaLabel="Navigate to create question" path={{ pathname: "/question" }} outline>Create Question</OSUButton>
                        </div>
                    }
                </Heading4>
                {renderQuestionData()}
            </div>
        );
    }
}

