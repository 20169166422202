import { JOB_FAMILY_FETCH_DATA, SUBMIT_FORM_JOB_PROFILE_TYPE,SUBMIT_DEFAULT_QUESTIONNAIRE,  SUBMIT_CANDIDATE_DATA, SUBMIT_VERIFIED_FORM, SUBMIT_REFERENCE_TYPE, UPDATE_JOB_PROFILES_SEARCH, UPDATE_JOB_FAMILY_FILTER, SEND_COLLECTION_ERROR, SEND_COLLECTION_PROCESSING, SEND_COLLECTION_SUCCESS, SEND_COLLECTION_CLEARED , SEND_COLLECTION_MESSAGE} from '../actions-index'

export function jobFamilies(state = [], action = {}) {
    switch (action.type) {
        case JOB_FAMILY_FETCH_DATA:
            return action.payload;
        default:
            return state;
    }
}

export function pendingCandidates(state = [], action = {}) {
    switch (action.type) {
        case SUBMIT_CANDIDATE_DATA:
            return action.payload
        default:
            return state;
    }
}

export function pendingReferenceType(state = "", action = {}) {
    switch (action.type) {
        case SUBMIT_REFERENCE_TYPE:
            return action.payload
        default:
            return state;
    }
}

export function pendingFormJobProfile(state = "", action = {}) {
    switch (action.type) {
        case SUBMIT_FORM_JOB_PROFILE_TYPE:
            return action.payload
        default:
            return state;
    }
}

export function defaultQuestionnaire(state = "", action = {}) {
    switch (action.type) {
        case SUBMIT_DEFAULT_QUESTIONNAIRE: {
            console.log("inside defaultQuestionnaire reducer", action.payload);
            return action.payload
        }
        default:
            return state;
    }
}

export function verifiedForm(state = "", action = {}) {
    switch (action.type) {
        case SUBMIT_VERIFIED_FORM:
            return action.payload
        default:
            return state;
    }
}

export function jobProfilesSearchQuery(state = "", action = {}) {
    switch (action.type) {
        case UPDATE_JOB_PROFILES_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export function jobFamilyFilter(state = "", action = {}) {
    switch (action.type) {
        case UPDATE_JOB_FAMILY_FILTER:
            return action.payload;
        default:
            return state;
    }
}

export function collectionStatus(state = "", action = {}) {
    switch (action.type) {
        case SEND_COLLECTION_PROCESSING:
            return action.payload;
        case SEND_COLLECTION_ERROR:
            return action.payload;
        case SEND_COLLECTION_SUCCESS:
            return action.payload;
        case SEND_COLLECTION_CLEARED:
            return action.payload;
        default:
            return state;
    }
}

export function collectionMessage(state = "", action = {}) {
    switch (action.type) {
        case SEND_COLLECTION_MESSAGE:
            return action.payload;
        default:
            return state;
    }
}