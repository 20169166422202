import { SET_USER, USER_IS_LOADING, UPDATE_BREADCRUMB_TRAIL } from '../actions-index';

export function breadcrumbTrail(state = null, action = {}) {
    switch (action.type) {
        case UPDATE_BREADCRUMB_TRAIL:
            return action.payload;
        default:
            return state;
    }
}

export function user(state = {}, action = {}) {
    switch (action.type) {
        case SET_USER:
            return action.payload;
        default:
            return state;
    }
}

export function userIsLoading(state = false, action = {}) {
    switch (action.type) {
        case USER_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}