import React, { Component } from "react";
import { Alert } from "reactstrap";
import { ExpandCollapsePanel, Heading4, Subtitle1, OSUError, OSULoading, Overline, Heading5, OSUButton, QuestionnaireView } from "osu-react-components";
import { isEmpty } from 'lodash'

export default class FormsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Job Profile",
            jobProfileId: props.match.params.id
        }
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.props.updateBreadcrumbTrail([
            { path: "/job-profiles", text: "Job Profiles" },
            { path: `/job-profiles/${this.state.jobProfileId}`, text: this.state.jobProfileId, active: true }
        ]);
        this.loadJobProfile();
    }

    componentDidUpdate() {
        if (this.pageContent) {
            this.pageContent.focus();
        }
    }

    componentWillUnmount() {
        this.props.updateBreadcrumbTrail([]);
    }

    findJobProfileQuestionnaire = () => {
        this.props.findJobProfileQuestionnaire(this.state.jobProfileId);
    };

    loadJobProfile = () => {
        this.props.findJobProfiles(this.state.jobProfileId);
        this.findJobProfileQuestionnaire();
    }

    render() {
        const { jobProfileQuestionnaireError, jobProfileQuestionnaireLoading, jobProfileQuestionnaire } = this.props

        const renderQuestionnaire = () => {
            if (jobProfileQuestionnaireError) {
                return (
                    <Alert data-testid="errorAlert" color="danger" className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        Questionnaire cannot be loaded at this time.
                        <OSUButton uppercase ariaLabel="Reload the questionnaire associated with this job profile" color="red" onClick={() => this.findJobProfileQuestionnaire()}>Reload</OSUButton>
                    </Alert>
                );
            } else if (jobProfileQuestionnaireLoading) {
                return <OSULoading text="Loading Questionnaire..." />
            } else {
                return (
                    <div>
                        <ExpandCollapsePanel ariaLabel="Expand job profile questionniare view">
                            <QuestionnaireView questions={!!jobProfileQuestionnaire && !!jobProfileQuestionnaire.questions ? jobProfileQuestionnaire.questions : [] } />
                        </ExpandCollapsePanel>
                        {isEmpty(this.props.jobProfileQuestionnaire) ?
                            <Alert data-testid="createQuestionnaireAlert" color="primary" className="customized-action-alert">
                                There is currently no questionnaire associated with this job profile.
                                <hr className="w-10 d-block d-sm-none" />
                                <OSUButton ariaLabel="Navigate to create questionnaire view" path={`/job-profiles/${this.state.jobProfileId}/questionnaire`} solid>Create Questionnaire</OSUButton>
                            </Alert>
                            : <OSUButton ariaLabel="Navigate to replace questionnaire view" path={`/job-profiles/${this.state.jobProfileId}/questionnaire`} outline>Replace Questionnaire</OSUButton>
                        }
                    </div>
                );
            }
        };

        const renderPageContent = () => {
            if (this.props.jobProfilesError) {
                return (<OSUError text={`Job Profile ${this.state.jobProfileId} cannot be loaded at this time.`} ariaLabel={`Reload job profile ${this.state.jobProfileId}`} actionText="Reload" onClick={() => this.loadJobProfile()} />);
            } else if (this.props.jobProfilesLoading) {
                return (<OSULoading text={`Loading Job Profile ${this.state.jobProfileId}...`} />);
            } else {
                const jobProfile = this.props.jobProfiles[0];
                if (!jobProfile) {
                    return (<OSUError ariaLabel="Navigate to job profiles" text={`Job Profile ${this.state.jobProfileId} cannot be found.`} actionText="Go to Job Profiles" path="/job-profiles" />);
                } else {
                    document.title = `${this.state.pageTitle} - ${jobProfile.name}`;
                    return (
                        <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                            <Heading4 dataTestId="pageHeading" id="pageHeader" className="mb-2">{jobProfile.name}</Heading4>
                            <hr />
                            <div className="d-flex flex-column flex-sm-row mb-2">
                                <Subtitle1 dataTestId="profileId" className="mr-5">
                                    <Overline className="mb-1" color="gray">ID</Overline>
                                    {jobProfile.id}
                                </Subtitle1>
                                <Subtitle1 dataTestId="profileFamily" className="mr-5">
                                    <Overline className="mb-1" color="gray">Family</Overline>
                                    {jobProfile.familyName}
                                </Subtitle1>
                            </div>
                            <hr />
                            <div>
                                <Heading5 className="mt-2">Questionnaire</Heading5>
                                {renderQuestionnaire()}
                            </div>
                        </div>
                    );
                }
            }
        };

        return renderPageContent();
    }
}
