import { POSTING_ITEM_FETCH_DATA, POSTINGS_FETCH_DATA, POSTINGS_HAS_ERROR, POSTING_IS_LOADING, POSTINGS_LIST_IS_LOADING, UPDATE_POSTINGS_SEARCH, UPDATE_POSTING_ITEM, POSTING_ITEM_HAS_ERROR } from '../actions-index';

export function postings(state = [], action = {}) {
    switch (action.type) {
        case POSTINGS_FETCH_DATA:
            return action.payload;
        default:
            return state;
    }
}

export function postingsHasError(state = false, action = {}) {
    switch (action.type) {
        case POSTINGS_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }
}

export function postingsSearchQuery(state = "", action = {}) {
    switch (action.type) {
        case UPDATE_POSTINGS_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export function postingItem(state = {}, action = {}) {
    switch (action.type) {
        case UPDATE_POSTING_ITEM:
            return action.payload;
        case POSTING_ITEM_FETCH_DATA:
            return action.payload;
        default:
            return state;
    }
}

export function postingListIsLoading(state = false, action = {}) {
    switch (action.type) {
        case POSTINGS_LIST_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}
export function postingIsLoading(state = false, action = {}) {
    switch (action.type) {
        case POSTING_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function postingHasError(state = false, action = {}) {
    switch (action.type) {
        case POSTING_ITEM_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }}