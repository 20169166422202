import { combineReducers } from 'redux';
// import { user } from './reducers/app';
import { user, userIsLoading, breadcrumbTrail } from './App/reducers';
import { postings, postingsHasError, postingIsLoading, postingListIsLoading, postingItem, postingsSearchQuery, postingHasError } from './Postings/reducers';
import { candidatesIsLoading, candidatesHasError, candidates, candidatesSearchQuery } from './Candidates/reducers';
import { pendingCandidates, pendingReferenceType, pendingFormJobProfile, verifiedForm, jobProfilesSearchQuery, defaultQuestionnaire, jobFamilyFilter, collectionStatus, collectionMessage } from './Collection/reducers';
import * as jobProfileReducers from './JobProfiles/reducers';
import * as questionReducers from './Questions/reducers';
import * as referenceReducers from './Reference/reducers';

export default combineReducers({
    breadcrumbTrail,
    user,
    userIsLoading,
    postings,
    postingsHasError,
    postingListIsLoading,
    postingItem,
    postingsSearchQuery,
    postingIsLoading,
    postingHasError,
    candidates,
    candidatesIsLoading,
    candidatesHasError,
    candidatesSearchQuery,
    collectionStatus,
    collectionMessage,
    jobProfilesSearchQuery,
    defaultQuestionnaire,
    jobFamilyFilter,
    pendingCandidates,
    pendingReferenceType,
    pendingFormJobProfile,
    verifiedForm,
    ...jobProfileReducers,
    ...questionReducers,
    ...referenceReducers
});