import React from 'react';
import { Heading6 } from 'osu-react-components';
import Candidates from '../../Candidates/containers/Candidates';

class ConfirmCandidates extends React.Component {
    render() {
        let { pendingCandidates, screenSize, candidatesIsLoading, posting, previousButton, confirmationButton } = this.props;

        return <div>
            <Candidates hideStatus deactivateActions posting={posting} screenSize={screenSize} viewOnly={false}  />
            {!candidatesIsLoading && <Heading6 className="mt-4 mb-2">Confirm Candidates</Heading6>}
            {!candidatesIsLoading && !!previousButton && previousButton()}
            {!candidatesIsLoading && confirmationButton(!pendingCandidates || !Array.isArray(pendingCandidates) || pendingCandidates.length < 1)}
        </div>
    }
}

export default ConfirmCandidates;