import React, { Component } from "react";
import { Heading4, Heading5, OSUButton, OSUError, OSULoading, QuestionnaireView, QuestionSelection } from "osu-react-components";
import Select from 'react-select';

export default class Questionnaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Questionnaire",
            questions: [],
            submitButtonDisabled: true,
            isEditing: false,
            questionnaireType: null
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.props.updateBreadcrumbTrail([
            { path: "/default-questionnaire", text: "Default Questionnaire" }
        ]);
        this.loadQuestionnaire();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.pageContent) {
            this.pageContent.focus();
        }

        if (prevProps.submitJobProfileQuestionnaireProcessing === true && this.props.submitJobProfileQuestionnaireProcessing === false) {
            this.props.findJobProfileQuestionnaire(this.state.questionnaireType.value);
        }
    }

    componentWillUnmount() {
        this.props.resetSubmitJobProfileQuestionnaireFlags();
        this.props.updateBreadcrumbTrail([]);
    }

    loadQuestionnaire = () => {
        this.props.findQuestions();
    }

    onQuestionSelectionEvent = (selections, hasUnansweredQuestions) => {
        this.setState({
            questions: selections,
            submitButtonDisabled: hasUnansweredQuestions
        })
    }

    replaceDefaultQuestionnaire = () => {
        if (this.state.isEditing) {
            this.setState({
                isEditing: false
            });
        }
        else {
            this.setState({
                isEditing: true
            });
        }
    }

    selectType(questionnaireType) {
        this.props.findJobProfileQuestionnaire(questionnaireType.value);
        this.setState({
            questionnaireType: questionnaireType
        });
    }

    submitQuestionnaire = () => {
        let questionnaire = {};
        questionnaire.application = "REFERENCE";
        questionnaire.quizName = `Default Questionnaire`;
        questionnaire.questions = this.state.questions;
        this.setState({ submittedQuestionnaire: questionnaire });
        this.props.submitJobProfileQuestionnaire(this.state.questionnaireType.value, questionnaire);
    }

    render() {
        // console.log("isEditing: ", this.state.isEditing);
        const renderQuestionnaire = () => {
            let questions = [];
            if (this.props.questions && this.props.questions.length > 0) 
                for (let question of this.props.questions) {
                    if (!question.disabled)
                        questions.push(question);
                }
                return (
                    <div>
                        {!this.state.isEditing && this.props.jobProfileQuestionnaire &&
                            <QuestionnaireView questions={this.props.jobProfileQuestionnaire.hasOwnProperty('questions') ? this.props.jobProfileQuestionnaire.questions : []} />
                        }
                        {this.state.isEditing &&
                            <div>
                                <Heading5 dataTestId="pageSubheading" className="mt-2">Questionnaire</Heading5>
                                <QuestionSelection questions={questions}
                                    onQuestionSelectionEvent={(selections, hasUnansweredQuestions) => this.onQuestionSelectionEvent(selections, hasUnansweredQuestions)} />
                                <OSUButton ariaLabel={`Submit default questionnaire for job profiles `} color="blue" disabled={this.state.submitButtonDisabled} onClick={() => { this.submitQuestionnaire(); this.replaceDefaultQuestionnaire(); }}>Submit</OSUButton>
                            </div>
                        }
                    </div>

                );
            }

            const renderPageContent = () => {
                if (this.props.jobProfilesError || this.props.questionsError) { // Questionnaire Error 
                    return (<OSUError text="Questionnaire cannot be loaded at this time." actionText="Reload" ariaLabel="Reload questionnaire" onClick={() => this.loadQuestionnaire()} />);
                } else if (this.props.questionsLoading || this.props.jobProfileQuestionnaireLoading || this.props.submitJobProfileQuestionnaireProcessing) { // Questionnaire Loading
                    return (<OSULoading text="Loading Questionnaire..." />);
                } else if (this.props.submitJobProfileQuestionnaireError) { // Submit Questionnaire Error
                    return (<OSUError text="An error occurred while processing the questionnaire." ariaLabel="Resubmit questionnaire" actionText="Re-Submit" onClick={() => this.reSubmitQuestionnaire()} />);
                } else {

                    document.title = `${this.state.pageTitle}`;
                    return (
                        <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                            <Heading4 dataTestId="pageHeading" id="pageHeader" className="mb-2">Select Default Questionnaire</Heading4>
                            <hr />
                            <Select className="w-25" value={this.state.questionnaireType ? this.state.questionnaireType : null} placeholder="Questionnaire Type..." options={[{ value: "DEFAULT_HIGH_VOLUME", label: "High Volume Reference" }, { value: "DEFAULT_PROFESSIONAL", label: "Professional Reference" }]} onChange={(e) => this.selectType(e)} />
                            <br />
                            {renderQuestionnaire()}
                            {!this.state.isEditing && this.state.questionnaireType && <OSUButton ariaLabel="Navigate to replace default questionnaire view" outline onClick={() => this.replaceDefaultQuestionnaire()}>Replace Default Questionnaire</OSUButton>}
                        </div>
                    );

                }
            };

            return renderPageContent();
        }
    }