import {API, Auth} from "aws-amplify";
import { buildAction, UPDATE_REFERENCE_SUCCESS, UPDATE_REFERENCE_ERROR, UPDATE_REFERENCE_PROCESSING, REFERENCES_FETCH_DATA, REFERENCES_LOADING, REFERENCES_HAS_ERROR, REFERENCE_TOKEN_STATUS, REFERENCE_SUBMISSION_LINK } from '../actions-index';

const apiName = "ReferenceManagement";

export function updateReference(reference) {
    console.log(reference)
    return (dispatch) => {
        let errorHandler = (error) => {
            console.error("Update Reference Error:", error);
            dispatch(buildAction(UPDATE_REFERENCE_ERROR, true));
        }

        dispatch(buildAction(UPDATE_REFERENCE_PROCESSING, true));
        API.post(apiName, "/dataaccess", {
            body: {
                action: "updateReference",
                key: "job",
                ...reference
            }
        })
            .then(
                () => {
                    dispatch(buildAction(UPDATE_REFERENCE_SUCCESS, true));
                },
                errorHandler
            )
            .catch(errorHandler)
            .finally(() => dispatch(buildAction(UPDATE_REFERENCE_PROCESSING, false)));
    };
}

export function resetUpdateReferenceFlags() {
    return (dispatch) => {
        dispatch(buildAction(UPDATE_REFERENCE_ERROR, false));
        dispatch(buildAction(UPDATE_REFERENCE_SUCCESS, false));
    }
}

export function referencesFetchData(postingId, candidateId) {
    return dispatch => {
        let errorHandler = (error) => {
            console.error("Error fetching references", error);
            dispatch(referencesHasError(true))
        };

        dispatch({ type: REFERENCES_LOADING, payload: true });
        API.get('ReferenceManagement', `/job/${postingId}/candidate/${candidateId}/references`)
            .then(
                (response) => {
                    dispatch(receivedReferences(candidateId, response));
                },
                errorHandler
            )
            .catch(errorHandler)
            .finally(() => dispatch({ type: REFERENCES_LOADING, payload: false }));
    };
}

export function referencesHasError(error) {
    return {
        type: REFERENCES_HAS_ERROR,
        payload: error
    }
}

export function receivedReferences(candidateId, references) {
    return {
        type: REFERENCES_FETCH_DATA,
        payload: {
            candidateId,
            references
        }
    }
}

export function generateReferenceToken(postingId, candidateId, referenceId) {
    return dispatch => {
        dispatch(buildAction(REFERENCE_TOKEN_STATUS, 'loading'))
        API.post(apiName, "/encrypt", {
            body: {
                "key": {
                    postingId,
                    candidateId,
                    referenceId
                }
            }
        }).then(
            (response) => {
                if (response.status !== 200 || response.status === 401) {
                    if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                        Auth.signOut();
                    }
                }
                if (!!response.url) {
                    dispatch(buildAction(REFERENCE_SUBMISSION_LINK, response.url))
                    dispatch(buildAction(REFERENCE_TOKEN_STATUS, ''))
                } else {
                    console.error("Response error generating token");
                    dispatch(buildAction(REFERENCE_TOKEN_STATUS, 'error'))
                }
            },
            // errorHandler
        ).catch(() => dispatch(buildAction(REFERENCE_TOKEN_STATUS, 'error')))
    }
}

