function scrubHtml(data) {
    console.log("scrubbing html");
    const allowedTags = [
        "br",
        "a",
        "b",
        "i",
        "ul",
        "li",
        "u",
        "span",
        "div",
        "p"
    ]

    let readAheadFlag = false;
    let buffer = "";
    let startPos;
    let endPos;
    let endBuffer = "";
    let lastPos = 0;
    for (var i = 0; i < data.length; i++) {
        if (data.charAt(i) === ">") {
            readAheadFlag = false;
            endPos = i;
            buffer += data.charAt(i);
            let found = false;
            for (let tag of allowedTags) {
                if (buffer.startsWith("<" + tag) || buffer.startsWith("</" + tag))
                    found = true;
            }
            if (lastPos === 0) {
                lastPos--;
            }
            if (found === true) {
                endBuffer += data.substring(lastPos + 1, endPos + 1);
            }
            else {                    
                endBuffer += data.substring(lastPos + 1, startPos);
            }

            lastPos = endPos;
            buffer = "";
            startPos = undefined;
            endPos = undefined;
        }
        if (readAheadFlag === true) {
            buffer += data.charAt(i);
        }
        if (data.charAt(i) === "<") {
            readAheadFlag = true;
            startPos = i;
            buffer += data.charAt(i);
        }
    }

    console.log("output html: ", endBuffer);
    return endBuffer;
}

module.exports = {
    scrubHtml: scrubHtml
}