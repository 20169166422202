import React, { Component } from 'react';
import { Alert, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Body2, Overline, Caption, Heading4, Icon, OSUButton, OSUError, OSULoading, Subtitle1 } from 'osu-react-components';
import emailValidator from 'email-validator';
import { capitalize } from 'lodash';
import '../reference.css';

const phoneAllowedCharsRegExp = new RegExp(/^[-+\s().0-9]+$/);

export default class Reference extends Component {
    constructor(props) {
        super(props);
        let locationState = this.props.location.state || {};
        let willRender = locationState.posting && locationState.candidate && locationState.reference;
        let posting = locationState.posting || {};
        if(!posting.id) {
            posting.id = this.props.match.params.jobId; // used by error handling
        }
        let candidate = locationState.candidate || {};
        let reference = locationState.reference || {};
        this.state = {
            pageTitle: 'Reference',
            willRender,
            posting,
            candidate,
            reference,
            modalOpen: false,
            modalFormId: "modalForm",
            modalFormValid: false,
            modalPhone: reference.phone || '',
            modalPhoneInvalid: false,
            modalEmail: reference.email || '',
            modalEmailInvalid: false,
            referenceTokenRetry: false
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        const { posting, candidate, reference } = this.state;
        if (this.state.willRender) {
            this.props.updateBreadcrumbTrail([
                { path: '/', text: 'postings' },
                { path: `/postings/${posting.id}`, text: posting.id },
                { text: candidate.id && reference.sk && `Candidate: ${candidate.fullName} - Reference: ${reference.name}`, active: true }
            ]);
           !!reference.data && reference.data === 'SENT' && !!posting.referenceType && this.generateReferenceToken();
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateReferenceSuccess === false && this.props.updateReferenceSuccess === true) {
            this.onUpdateReferenceSuccess();
        }
    }

    componentWillUnmount() {
        if (this.state.willRender) {
            this.props.updateBreadcrumbTrail([]);
        }
    }

    generateReferenceToken = () => {
        const { posting, candidate, reference } = this.state;
        const email = () => {
            if (!!reference && !!reference.sk && typeof reference.sk === 'string') {
                const parts = reference.sk.split('#');
                return parts.length > 1 ? parts[1] : null;
            }
            return null;
        }
        this.props.generateReferenceToken(posting.id, candidate.id, email());
    }

    isModalEmailValid = (modalEmail) => {
        return modalEmail && emailValidator.validate(modalEmail);
    }

    isModalPhoneValid = (modalPhone) => {
        let modalPhoneDigits = modalPhone.match(/\d/g) || [];
        return modalPhone && phoneAllowedCharsRegExp.test(modalPhone) && modalPhoneDigits.length >= 10;
    }

    onModalClosed = () => {
        this.props.resetUpdateReferenceFlags();
    }

    onModalSubmit = () => {
        this.props.resetUpdateReferenceFlags();
        this.updateReference();
    }

    onUpdateReferenceSuccess = () => {
        // update the existing reference to display the new values
        let reference = this.state.reference;
        reference.phone = this.state.modalPhone;
        reference.email = this.state.modalEmail;

        this.setState({
            reference,
            modalOpen: false, // close the modal
            modalFormValid: false // reset form validation (until the form changes again)
        });
    }

    setModalOpen = (modalOpen) => {
        this.setState({ modalOpen });
    }

    updateReference = () => {
        this.props.updateReference({
            pk: this.state.posting.id,
            sk: this.state.reference.sk,
            name: this.state.reference.name,
            email: this.state.modalEmail,
            phone: this.state.modalPhone,
            candidateName: this.state.candidate.fullName
        });
    }

    validateModalForm = () => {
        let modalForm = document.getElementById(this.state.modalFormId);
        let modalFormData = new FormData(modalForm);
        let modalFormFields = {};
        for (let lvp of modalFormData.entries()) {
            modalFormFields[lvp[0]] = lvp[1];
        };

        let modalPhone = modalFormFields["modalPhone"];
        let modalPhoneValid = this.isModalPhoneValid(modalPhone);
        let modalPhoneChanged = modalPhone !== this.state.reference.phone;

        let modalEmail = modalFormFields["modalEmail"];
        let modalEmailValid = this.isModalEmailValid(modalEmail);
        let modalEmailChanged = modalEmail !== this.state.reference.email;

        let modalFormValid = modalPhoneValid && modalEmailValid && (modalPhoneChanged || modalEmailChanged);

        this.setState({
            modalPhone,
            modalPhoneInvalid: !modalPhoneValid,
            modalEmail,
            modalEmailInvalid: !modalEmailValid,
            modalFormValid
        });
    }

    renderModal = () => {
        return (
            <Modal isOpen={this.state.modalOpen} onClosed={this.onModalClosed}>
                <ModalHeader>
                    <Overline color="gray">Edit Reference</Overline>
                    {this.state.reference.name}
                </ModalHeader>
                <ModalBody>
                    {this.props.updateReferenceError &&
                        <Alert color="danger">There was an error processing the reference. Please retry.</Alert>
                    }
                    {this.props.updateReferenceProcessing &&
                        <OSULoading text="Processing Reference..." />
                    }
                    {!this.props.updateReferenceProcessing &&
                        <Form id={this.state.modalFormId}>
                            <FormGroup>
                                <Label for="modalPhone">
                                    <Body2 color="gray">Phone Number</Body2>
                                </Label>
                                <Input name="modalPhone" value={this.state.modalPhone} invalid={this.state.modalPhoneInvalid} onChange={this.validateModalForm} />
                                <FormFeedback>Phone Number is invalid</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="modalEmail">
                                    <Body2 color="gray">Email Address</Body2>
                                </Label>
                                <Input name="modalEmail" value={this.state.modalEmail} invalid={this.state.modalEmailInvalid} onChange={this.validateModalForm} />
                                <FormFeedback>Email Address is invalid</FormFeedback>
                            </FormGroup>
                        </Form>
                    }
                </ModalBody>
                <ModalFooter>
                    {!this.props.updateReferenceProcessing &&
                        [<OSUButton ariaLabel="Cancel updating reference" key="cancel" color="gray" link uppercase={false} className="mr-1" onClick={() => this.setModalOpen(false)}>Cancel</OSUButton>,
                        <OSUButton ariaLabel="Submit updates to reference information" key="submit" color="blue" disabled={!this.state.modalFormValid} onClick={() => this.onModalSubmit()}>Submit</OSUButton>]
                    }
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const { referenceTokenLoading, referenceTokenError } = this.props
        if (!!referenceTokenLoading && !this.state.referenceTokenRetry) {
            return <OSULoading text="Loading Reference..." />
        }
        if (!this.state.willRender) {
            return (<OSUError ariaLabel={`Navigate to posting ${this.state.posting.id}`} text="Reference cannot be found." actionText="Go to Posting" path={`/postings/${this.state.posting.id}`} />);
        } else {
            const { pageTitle, posting, candidate, reference } = this.state;
            document.title = `${pageTitle} - ${reference.name}`;
            const formatData = (text) => !!text && !!text.trim() && typeof text === 'string' && text.toLowerCase().includes('error') ? 'Error' : capitalize(text);
            const renderReferenceSubmissionLink = () => {
                if(!!posting.referenceType && !!reference.data && reference.data === "SENT") {
                    if(referenceTokenError) {
                        return (
                            <OSUButton ariaLabel="Reload reference submission link" color="red" onClick={() => { this.setState({ referenceTokenRetry: true }); this.generateReferenceToken(); }} link uppercase={false}>
                                {` Reload Reference Submission Link `}
                                <Icon className="ml-1" type="refresh" color="red" />
                            </OSUButton>
                        );
                    } else {
                        return (
                            <OSUButton ariaLabel="Navigate to new site to respond on behalf of reference" url={this.props.submissionLink} link uppercase={false}>
                                {`Respond on Behalf of Reference (Opens in new page)`}
                                <Icon className="ml-2" type="external-link" color="blue" />
                            </OSUButton>
                        );
                    }
                } else {
                    return null;
                }
            }

            return (
                <div>
                    <Overline color="gray">Reference History</Overline>
                    <Heading4>
                        {reference.name}
                    </Heading4>
                    <div className="d-flex flex-column flex-sm-row item-wrapper ">
                        <div className="d-flex flex-row mr-5 mb-2 even-item">
                            <div className="d-flex flex-column flex-md-row">
                                <Subtitle1 className="mr-4">
                                    <Overline className="mb-1" color="gray">Phone Number</Overline>
                                    {reference.phone || 'No Phone Number Found'}
                                </Subtitle1>
                                <Subtitle1>
                                    <Overline className="mb-1" color="gray">Email Address</Overline>
                                    {reference.email || 'No Email Address Found'}
                                </Subtitle1>
                            </div>
                            <OSUButton ariaLabel="Edit Reference" color="white" className="ml-1" onClick={() => this.setModalOpen(true)}>
                                <Icon type="pencil" color="gray" />
                            </OSUButton>
                            {this.renderModal()}
                        </div>
                        <Subtitle1 className="mb-2 even-item">
                            <Overline className="mb-1" color="gray">Status</Overline>
                            {!!reference.data && !!reference.data.trim() ? formatData(reference.data) : 'No Status Found'}
                        </Subtitle1>
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row item-wrapper">
                        <Subtitle1 className="mr-5 mb-2 even-item">
                            <Overline className="mb-1" color="gray">Candidate</Overline>
                            {candidate.fullName || 'No Candidate Found'}
                            <div className="d-flex">
                                <Caption className="mr-4">{candidate.email}</Caption>
                                <Caption>{candidate.phone}</Caption>
                            </div>
                        </Subtitle1>
                        <Subtitle1 className="mb-2 even-item">
                            <Overline className="mb-1" color="gray">Job Posting</Overline>
                            {posting.title ? `${posting.title} ${posting.hasOwnProperty('id') && `(${posting.id})`}` : 'No Job Posting Found'}
                            {posting.primaryRecruiter && <Caption>{'Recruiter: ' + posting.primaryRecruiter}</Caption>}
                        </Subtitle1>
                    </div>
                    <hr />
                    {renderReferenceSubmissionLink()}
                </div>
            )
        }
    }
};