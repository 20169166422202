import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { findJobProfileQuestionnaire, updateBreadcrumbTrail, resetSubmitJobProfileQuestionnaireFlags } from '../../actions-index';
import { findQuestions } from '../../Questions/actions';
import { submitJobProfileQuestionnaire } from '../actions';
import DefaultQuestionnaire from '../components/DefaultQuestionnaire';

const mapStateToProps = (state, props) => {
    return {
        jobProfileQuestionnaire : state.jobProfileQuestionnaire,
        jobProfileQuestionnaireError: state.jobProfileQuestionnaireError,
        jobProfileQuestionnaireLoading: state.jobProfileQuestionnaireLoading,
        submitJobProfileQuestionnaireSuccess: state.submitJobProfileQuestionnaireSuccess,
        submitJobProfileQuestionnaireProcessing: state.submitJobProfileQuestionnaireProcessing,
        submitJobProfileQuestionnaireError: state.submitJobProfileQuestionnaireError,
        questions: state.questions,
        questionsError: state.questionsError,
        questionsLoading: state.questionsLoading,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findJobProfileQuestionnaire: (jobProfileId) => dispatch(findJobProfileQuestionnaire(jobProfileId)),
        findQuestions: () => dispatch(findQuestions()),
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        resetSubmitJobProfileQuestionnaireFlags: () => dispatch(resetSubmitJobProfileQuestionnaireFlags()),
        submitJobProfileQuestionnaire: (jobProfileId, questionnaire) => dispatch(submitJobProfileQuestionnaire(jobProfileId, questionnaire)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultQuestionnaire));
