import { getActionState, UPDATE_REFERENCE_SUCCESS, UPDATE_REFERENCE_ERROR, UPDATE_REFERENCE_PROCESSING, REFERENCES_FETCH_DATA, REFERENCES_LOADING, REFERENCES_HAS_ERROR, REFERENCE_TOKEN_STATUS, REFERENCE_SUBMISSION_LINK } from '../actions-index';

export function updateReferenceSuccess(state = false, action = {}) {
    return getActionState(action, [UPDATE_REFERENCE_SUCCESS], state);
}

export function updateReferenceError(state = false, action = {}) {
    return getActionState(action, [UPDATE_REFERENCE_ERROR], state);
}

export function updateReferenceProcessing(state = false, action = {}) {
    return getActionState(action, [UPDATE_REFERENCE_PROCESSING], state);
}

export function references(state = [], action = {}) {
    switch (action.type) {
        case REFERENCES_FETCH_DATA:
            return action.payload;
        default:
            return state;
    }
}

export function referencesLoading(state = false, action = {}) {
    switch (action.type) {
        case REFERENCES_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export function referencesHasError(state = false, action = {}) {
    switch (action.type) {
        case REFERENCES_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }
}

export function referenceSubmissionLink(state = '', action = {}) {
    switch (action.type) {
        case REFERENCE_SUBMISSION_LINK:
            return action.payload
        default:
            return state;
    }
}

export function referenceTokenStatus(state = '', action = {}) {
    switch (action.type) {
        case REFERENCE_TOKEN_STATUS:
            return action.payload;
        default:
            return state;
    }
}