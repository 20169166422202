import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Question from "../components/Item";
import { resetSubmitQuestionFlags, submitQuestion } from '../../actions-index';

const mapStateToProps = (state, props) => {
    return {
        submitQuestionDuplicate: state.submitQuestionDuplicate,
        submitQuestionError: state.submitQuestionError,
        submitQuestionProcessing: state.submitQuestionProcessing,
        submitQuestionSuccess: state.submitQuestionSuccess,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSubmitQuestionFlags: () => dispatch(resetSubmitQuestionFlags()),
        submitQuestion: (question) => dispatch(submitQuestion(question))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Question));
