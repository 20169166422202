import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import App from './../components';
import { setUser, updateBreadcrumbTrail } from '../actions';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        userIsLoading: state.userIsLoading,
        breadcrumbTrail: state.breadcrumbTrail
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: () => dispatch(setUser()),
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

