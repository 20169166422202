import React from 'react';
import { Body1, ExpandCollapsePanel, Heading5, Heading6, OSUButton, OSUError, OSULoading, QuestionnaireView, QuestionSelection } from 'osu-react-components';
import { Alert } from 'reactstrap';
import '../collection.css';
import * as _ from 'lodash';

class FormView extends React.Component {
    constructor(props) {
        super(props);
        let additionalQuestions = [];
        if (props.collection.verifiedForm !== "") {
            additionalQuestions = props.collection.verifiedForm.additionalQuestions;
        }
        this.state = {
            additionalQuestions
        };
        this.saveQuestionnaire = this.saveQuestionnaire.bind(this);
    }

    componentDidMount() {
        this.props.findQuestions();
        if (!!this.props.collection && !!this.props.collection.jobProfile && !!this.props.collection.jobProfile.id) {
            this.props.findJobProfileQuestionnaire(this.props.collection.jobProfile.id)
        }
    }

    componentDidUpdate() {
        console.log("FormReview props: ", this.props);
    }

    onQuestionSelectionEvent = (selections) => {
        this.setState({ additionalQuestions: selections });
    }

    saveQuestionnaire(questionnaire) {
        if (!_.isEmpty(questionnaire)) {
            this.props.updateVerifiedForm({
                questionnaire,
                additionalQuestions: this.state.additionalQuestions
            });
        }
    }

    renderAdditionalQuestions() {
        let questionnaireQuestions = this.props.jobProfileQuestionnaire ? this.props.jobProfileQuestionnaire.questions : [];
        let questionsFiltered = [];
        if (this.props.questions.length > 0)
            for (let question of this.props.questions) {
                if (!question.disabled)
                    questionsFiltered.push(question);
            }
        let questions = _.differenceBy(questionsFiltered, questionnaireQuestions, 'id'); // filter job profile questionnaire questions

        return (
            <div>
                <Heading5 className="mb-2">Additional Questions</Heading5>
                <QuestionSelection questions={questions} selections={this.state.additionalQuestions}
                    selectionRequired={false} onQuestionSelectionEvent={(selections) => this.onQuestionSelectionEvent(selections)} />
            </div>
        );
    }

    handleNoQuestionnaire(values) {
        const { admin, jobProfile } = values
        if (!admin) {
            return <Body1>{`There is no questionnaire associated with this form. Please contact an administrator to request a questionnaire for Job Profile ${jobProfile.id}, or select a difference profile.`}</Body1>
        }
        return <Alert color="primary" className="customized-action-alert">
            No questions have been associated with this questionnaire.
        <hr className="w-100 d-block d-sm-none" />
            <OSUButton ariaLabel={`Navigate to job profile ${jobProfile.id}`} path={{
                pathname: `/job-profiles/${jobProfile.id}`,
                state: {
                    jobProfile
                }
            }} solid>Create Questionnaire</OSUButton>
        </Alert>
    }

    render() {
        // const { jobFormJsx } = this;
        const { jobProfileQuestionnaire, collection, admin } = this.props;
        const { jobProfile } = collection;
        const jobFamilyQuestions = jobProfileQuestionnaire && jobProfileQuestionnaire.questions && Array.isArray(jobProfileQuestionnaire.questions) ? jobProfileQuestionnaire.questions : []
        const noQuestionnaire = !jobFamilyQuestions || !Array.isArray(jobFamilyQuestions) || jobFamilyQuestions.length < 1

        if (this.props.questionsError || this.props.jobProfileQuestionnaireError) {
            return (
                <OSUError ariaLabel={"Reload questionnaire for this job profile"} text="Questionnaire cannot be loaded at this time." onClick={() => this.props.findJobProfileQuestionnaire(jobProfile.id)} />
            );
        } else if (this.props.questionsLoading || this.props.jobProfileQuestionnaireLoading) {
            return <OSULoading text="Loading Questionnaire..." />
        }

        return <div>
            {noQuestionnaire
                ? this.handleNoQuestionnaire({ admin, jobProfile })
                : <ExpandCollapsePanel ariaLabel="Expand view for job profile questionnaire">
                    <QuestionnaireView questions={jobProfileQuestionnaire.questions} />
                </ExpandCollapsePanel>
            }
            {!noQuestionnaire && this.renderAdditionalQuestions()}
            <hr />
            <Heading6 className="mt-4 mb-2">Confirm Questionnaire</Heading6>
            {!!this.props.previousButton && this.props.previousButton()}
            {this.props.confirmationButton(noQuestionnaire, () => this.saveQuestionnaire(jobProfileQuestionnaire))}
        </div>
    }
}

export default FormView