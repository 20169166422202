import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { capitalize, isEmpty } from 'lodash';
import { ExpandCollapsePanel, OSULoading, OSUError, OSUButtonGroup, Overline, Body2, OSUButton, Heading4, Subtitle1, Heading5 } from 'osu-react-components';
import Candidates from '../../Candidates/containers/Candidates';
import '../postings.css'

class PostingItem extends Component {
    constructor() {
        super();
        this.state = {
            pageTitle: 'Posting',
            candidatesConfirmed: false,
            redirectForCollection: false,
            referenceTypeSelected: null
        }
        this.handleCollectRefButton = this.handleCollectRefButton.bind(this);
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        const postingId = this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') &&
            this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null;
        this.props.updateBreadcrumbTrail([
            {
                path: '/',
                text: 'postings'
            },
            {
                path: `/postings/${postingId}`,
                active: true,
                text: postingId
            }
        ]);
        this.props.fetchPostingItem(postingId);
    }

    componentWillUnmount() {
        this.props.submitCandidates([]);
        this.props.updateBreadcrumbTrail([]);
    }

    handleCollectRefButton(candidates, pendingCandidates, jobId) {
        const { posting } = this.props
        const { referenceType } = posting

        this.props.history.push({ pathname: `/postings/${posting.id}/edit-collection`, state: { ...posting, collection: { ...posting.collection, referenceType, candidates: pendingCandidates } } })
    }


    addReferenceType(type) {
        const { posting, candidates } = this.props;
        this.props.updateReferenceType(type);
        this.props.history.push({ pathname: `/postings/${posting.id}/create-collection`, state: { posting, candidates } });
    }

    

    render() {
        const { /*candidates = [], pendingCandidates, */ screenSize, postingIsLoading, postingHasError } = this.props;
        let posting = this.props.posting;
        const jobHasReferenceType = !!posting.referenceType && (posting.referenceType === 'QUESTIONNAIRE' || posting.referenceType === 'LETTER');
        //const candidatesAreSubmittable = !!pendingCandidates && pendingCandidates.length > 0;
        const postingId = this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') &&
            this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : posting.id;
        const invalidPosting = isEmpty(posting) || posting.id !== postingId
        if (postingIsLoading) {
            return (<OSULoading text={`Loading Posting ${postingId}...`} />);
        }
        if (postingHasError && !invalidPosting) {
            return <OSUError ariaLabel={`Reload posting ${postingId}`} text={`Unable to load posting ${postingId}.`} onClick={() => this.props.fetchPostingItem(postingId)} />
        }
        if (invalidPosting) {
            return (<OSUError ariaLabel="Navigate to postings" text={`Could not find posting.`} actionText="Go to Postings" path="/postings" />);
        }
        document.title = `${this.state.pageTitle} - ${posting.title}`;
        return <div>
            <Heading4 dataTestId="pageHeading">{posting.hasOwnProperty('title') ? posting.title : 'No Posting Name Found'}</Heading4>
            <hr />
            <div className="d-flex flex-column flex-sm-row mb-2">
                <Subtitle1 dataTestId="location" className="mr-5">
                    <Overline className="mb-1" color="gray">Location</Overline>
                    {posting.primaryLocation || 'No Primary Location Found'}
                </Subtitle1>
                <Subtitle1 dataTestId="recruiter" className="mr-5">
                    <Overline className="mb-1" color="gray">Recruiter</Overline>
                    {posting.primaryRecruiter || 'No Primary Recruiter Found'}
                </Subtitle1>
                <Subtitle1 dataTestId="postingId" className="mr-5">
                    <Overline className="mb-1" color="gray">Posting ID</Overline>
                    {posting.id || 'No Posting ID Found'}
                </Subtitle1>
                {jobHasReferenceType && <div>
                    <Subtitle1 dataTestId="referenceType" >
                        <Overline className="mb-1" color="gray" >Reference Type</Overline>
                        {capitalize(posting.referenceType)}
                    </Subtitle1>
                </div>}
            </div>
            {/* // d-flex flex-column flex-sm-row justify-content-between align-items-center */}
            {!jobHasReferenceType && <Alert className="customized-action-alert" color="primary">
                No reference type selected.
                <div className="action-group">
                    <hr className="w-100 d-block d-sm-none" />
                    <span className="mr-2 text-bold">
                        Select:
                    </span>
                    <OSUButtonGroup>
                        <OSUButton className="border-right" onClick={() => this.addReferenceType("QUESTIONNAIRE")} ariaLabel="Select Questionnaire" color={'blue'}>Questionnaire</OSUButton>
                        <OSUButton className="border-left" onClick={() => this.addReferenceType("LETTER")} ariaLabel="Select Letter" color={'blue'}>Letter</OSUButton>
                    </OSUButtonGroup>
                </div>
            </Alert>}
            {!isEmpty(posting.jobDescription) && <Body2 className="mt-3">
                <Overline className="mb-1" color="gray" >Description</Overline>
                <ExpandCollapsePanel ariaLabel="Expand job description view" maxPanelHeight={80}>
                    <span data-testid="jobDescription" dangerouslySetInnerHTML={{ __html: posting.jobDescription }}></span>
                </ExpandCollapsePanel>
            </Body2>}
            <div>
                <Heading5 className="mt-2 mb-1">Candidates</Heading5>
                {/* <Candidates isSearchable posting={posting} screenSize={screenSize} viewOnly /> */}
                <Candidates isSearchable posting={posting} screenSize={screenSize} viewOnly={!jobHasReferenceType} hideSelect />
                {/* {!!jobHasReferenceType && candidates.length > 0 && <div>
                    <Heading6 className="mt-2">Collect References For Selected Candidates</Heading6>
                    <OSUButton disabled={!candidatesAreSubmittable} className={"mt-2"} onClick={() => this.handleCollectRefButton(candidates, pendingCandidates, posting.id)}>Confirm Candidates</OSUButton>
                </div> */}
            </div>
        </div >
    }
}

export default PostingItem
