import { SET_USER, USER_IS_LOADING, UPDATE_BREADCRUMB_TRAIL } from "../actions-index";
import { Auth } from 'aws-amplify';

import { redirectToLogIn } from '../util/util';

export function updateBreadcrumbTrail(pathObjs) {
    return {
        type: UPDATE_BREADCRUMB_TRAIL,
        payload: pathObjs
    }
}

export function userIsLoading(isLoading) {
    return {
        type: USER_IS_LOADING,
        payload: isLoading
    }
}

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: user
    }
}

export function setUser() {
    return (dispatch) => {
        dispatch(userIsLoading(true));
        return Auth.currentSession()
            .then((session) => {
                // console.log('Token', session.idToken.jwtToken)
                dispatch(recievedSetUser(session.idToken.payload));
            })
            .catch((error) => { 
                if (error === 'No current user') {
                    redirectToLogIn();
                }
            }).finally(() => {
                dispatch(userIsLoading(false));
            });
    }
}
