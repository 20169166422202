import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { findJobProfiles, setJobProfileSearchQuery } from '../actions';
import JobProfilesList from '../components/List';

const mapStateToProps = (state, props) => {
    return {
        jobProfiles: state.jobProfiles,
        jobProfilesError: state.jobProfilesError,
        jobProfilesLoading: state.jobProfilesLoading,
        jobProfileSearchQuery: state.jobProfileSearchQuery,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        findJobProfiles: () => dispatch(findJobProfiles()),
        setJobProfileSearchQuery: (searchQuery) => dispatch(setJobProfileSearchQuery(searchQuery))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobProfilesList));
