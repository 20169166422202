import {
    POSTINGS_FETCH_DATA,
    POSTINGS_HAS_ERROR,
    POSTING_ITEM_FETCH_DATA,
    POSTING_ITEM_HAS_ERROR,
    POSTINGS_LIST_IS_LOADING,
    POSTING_IS_LOADING,
    UPDATE_POSTINGS_SEARCH,
    UPDATE_POSTING_ITEM
} from '../actions-index';
import {API, Auth} from 'aws-amplify';
import { orderBy } from 'lodash';
import * as transform from './transform';

export function postingsFetchData() {

    let apiGW = { name: 'ReferenceManagement', path: '/job/requisitions', params: { response: true } };

    return function (dispatch) {
        dispatch(postingsHasError(false)); // reset prior error (if necessary)
        dispatch(postingListIsLoading(true));
        return API.get(apiGW.name, apiGW.path, apiGW.params)
            .then(
                response => {
                    // console.log('this is response..', response)
                    if (response.status !== 200 || response.status === 401) {
                        if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                            Auth.signOut();
                        }
                    } else if (Array.isArray(response.data)) {
                        dispatch(receivedPostings(response.data));
                    } else {
                        throw new Error('Unexpected response data', JSON.stringify(response.data))
                    }
                },
                error => {
                    console.log('Error fetching postings', error);
                    dispatch(postingsHasError(true));
                }
            )
            .catch(error => {
                console.log('Error fetching postings', error);
                dispatch(postingsHasError(true));
            })
            .finally(() => dispatch(postingListIsLoading(false)));

    }
}

export function receivedPostings(postings) {
    return {
        type: POSTINGS_FETCH_DATA,
        payload: orderBy(postings, ['title', 'primaryRecruiter'], ['asc', 'asc'])
    }
}
export function postingsHasError(hasError) {
    return {
        type: POSTINGS_HAS_ERROR,
        payload: hasError
    }
}
export function postingListIsLoading(loading) {
    return {
        type: POSTINGS_LIST_IS_LOADING,
        payload: loading
    };
}


export function postingIsLoading(loading) {
    return {
        type: POSTING_IS_LOADING,
        payload: loading
    };
}

export function postingHasError(status) {
    return {
        type: POSTING_ITEM_HAS_ERROR,
        payload: status
    }
}

export function updatePostingItem(posting) {
    return {
        type: UPDATE_POSTING_ITEM,
        payload: posting
    }
}

export function postingItemFetchData(postingId) {
    console.log('posting item fetch', postingId, `/job/requisitions?id=${postingId}`)
    let apiGW = { name: "ReferenceManagement", path: `/job/requisitions?id=${postingId}`, params: { response: true } };
    return function (dispatch) {
        dispatch(postingHasError(false))
        dispatch(postingIsLoading(true));
        return API.get(apiGW.name, apiGW.path, apiGW.params)
            .then(response => {
                // console.log('this is response..', response)
                if (response.status !== 200 || response.status === 401) {
                    if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                        Auth.signOut();
                    }
                }else {
                    response.data[0].jobDescription = transform.scrubHtml(response.data[0].jobDescription);
                    dispatch(receivedPosting(response.data));
                }
            })
            .catch(error => {
                dispatch(postingHasError(true));
                console.log('Error fetching postings', error);
            })
            .finally(() => dispatch(postingIsLoading(false)));
    }
}

function receivedPosting(posting) {
    return {
        type: POSTING_ITEM_FETCH_DATA,
        payload: Array.isArray(posting) && posting.length > 0 ? posting[0] : {}
    }
}

export function updatePostingsSearch(query) {
    return {
        type: UPDATE_POSTINGS_SEARCH,
        payload: query
    }
}