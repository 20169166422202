import React, { Component } from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { Table, Heading4, OSUError, OSULoading, Icon, PaginationWrapper } from "osu-react-components";
import * as _ from "lodash";


export default class FormsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Job Profiles",
            jobProfileDataKeys: [
                { key: "profileName", label: "Profile", width: 70 },
                { key: "familyName", label: "Family", width: 30 }
            ],
            jobProfileDataPageIndex: 0
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.pageContent.focus();
        this.findJobProfiles();
    }

    findJobProfiles = () => {
        this.props.findJobProfiles();
    };

    onJobProfileSearch = (event) => {
        this.props.setJobProfileSearchQuery(event.target.value);
        // reset page counts
    };

    selectJobProfile = (id) => {
        console.log(`Selected Job Profile ${id}`); // TODO: Implement select action
    }

    updateJobProfileDataPageIndex = (pageIndex) => {
        this.setState({
            jobProfileDataPageIndex: pageIndex
        });
    }

    render() {
        const { screenSize } = this.props
        let jobProfileData = [];
        let jobProfileDataPageItems = [];
        const countPerPage = 10;

        if (this.props.jobProfiles.length > 0) {
            // build job profile data
            jobProfileData = this.props.jobProfiles.map(jobProfile => {
                jobProfile.rowPath = {
                    pathname: `/job-profiles/${jobProfile.id}`,
                    state: {
                        jobProfile: jobProfile
                    }
                };

                jobProfile.profileName = `${jobProfile.name} (${jobProfile.id})`;
                return jobProfile;
            });


            // execute search query (if necessary)
            if (this.props.jobProfileSearchQuery) {
                const matchString = (str = "") => str.toLowerCase().includes(this.props.jobProfileSearchQuery.toLowerCase());
                jobProfileData = jobProfileData.filter(jobProfile => {
                    return matchString(jobProfile.profileName) || matchString(jobProfile.familyName);
                });
            }

            jobProfileData = _.chunk(jobProfileData, countPerPage);
            jobProfileDataPageItems = jobProfileData[this.state.jobProfileDataPageIndex] || [];
        }

        const renderJobProfileData = () => {
            if (this.props.jobProfilesError) {
                return (
                    <OSUError ariaLabel="Reload job profiles" text="Job Profiles cannot be loaded at this time." onClick={() => this.findJobProfiles()} />
                );
            } else if (this.props.jobProfilesLoading) {
                return <OSULoading text="Loading Job Profiles..." />
            }
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon type="search" color="gray" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" placeholder="Search" value={this.props.jobProfileSearchQuery} onChange={(e) => this.onJobProfileSearch(e)} />
                </InputGroup>
                <PaginationWrapper totalPageCount={jobProfileData.length} updateDataIndex={this.updateJobProfileDataPageIndex} dataIndex={this.state.jobProfileDataPageIndex}>
                    <Table dataKeys={screenSize < 768 ? [{ key: "profileName", label: "Profile", width: 100 }] :this.state.jobProfileDataKeys} data={jobProfileDataPageItems} noDataMessage="No Job Profiles found" />
                </PaginationWrapper>
            </div>
        }

        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Heading4 id="pageHeader" className="mb-2">{this.state.pageTitle}</Heading4>
                {renderJobProfileData()}
            </div>
        );
    }
}