import React, { Component } from "react";
import { Body2, ExpandCollapsePanel, Heading4, Heading5, OSUButton, OSUError, OSULoading, QuestionnaireView, QuestionSelection } from "osu-react-components";

export default class Questionnaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Questionnaire",
            jobProfileId: this.props.match.params.id,
            questions: [],
            submitButtonDisabled: true,
            submittedQuestionnaire: null
        };
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.props.updateBreadcrumbTrail([
            { path: "/job-profiles", text: "Job Profiles" },
            { path: `/job-profiles/${this.state.jobProfileId}`, text: this.state.jobProfileId },
            { path: `/job-profiles/${this.state.jobProfileId}/questionnaire`, text: "Questionnaire", active: true }
        ]);
        this.loadQuestionnaire();
    }

    componentDidUpdate() {
        if(this.pageContent) {
            this.pageContent.focus();
        }
    }

    componentWillUnmount() {
        this.props.resetSubmitJobProfileQuestionnaireFlags();
        this.props.updateBreadcrumbTrail([]);
    }

    loadQuestionnaire = () => {
        this.props.findJobProfiles(this.state.jobProfileId);
        this.props.findQuestions();
    }

    onQuestionSelectionEvent = (selections, hasUnansweredQuestions) => {
        this.setState({
            questions: selections,
            submitButtonDisabled: hasUnansweredQuestions
        })
    }

    reSubmitQuestionnaire = () => {
        if(this.state.submittedQuestionnaire) {
            this.props.submitJobProfileQuestionnaire(this.state.jobProfileId, this.state.submittedQuestionnaire);
        }
    }

    submitQuestionnaire = () => {
        let questionnaire = {};
        questionnaire.application = "REFERENCE";
        questionnaire.quizName = `Job Profile ${this.state.jobProfileId} Questionnaire`;
        questionnaire.questions = this.state.questions;
        this.setState({ submittedQuestionnaire: questionnaire }); // store for confirmation and error re-submit
        this.props.submitJobProfileQuestionnaire(this.state.jobProfileId, questionnaire);
    }

    render() {

        const renderQuestionnaire = () => {
            let questions = [];
            if (this.props.questions && this.props.questions.length > 0) 
            for(let question of this.props.questions) {
                if(!question.disabled)
                    questions.push(question);
            }
            if(this.props.submitJobProfileQuestionnaireSuccess) { // Submit Questionnaire Confirmation
                return (
                    <div>
                        <Heading5 dataTestId="pageSubheading" className="mt-2">Questionnaire Confirmation</Heading5>
                        <hr />
                        <Body2>The questionnaire was successfully created and associated with the job profile.</Body2>
                        <hr />
                        {!!this.state.submittedQuestionnaire &&
                            <ExpandCollapsePanel ariaLabel="Expand questionnaire view">
                                <QuestionnaireView questions={this.state.submittedQuestionnaire.hasOwnProperty('questions') ? this.state.submittedQuestionnaire.questions : []} />
                            </ExpandCollapsePanel>
                        }
                        <OSUButton ariaLabel={`Navigage to Job Profile ${this.state.jobProfileId}`} outline uppercase color="gray" path={`/job-profiles/${this.state.jobProfileId}`}>View Job Profile</OSUButton>
                    </div>
                );
            } else { // Questionnaire
                return (
                    <div>
                        <Heading5 dataTestId="pageSubheading" className="mt-2">Questionnaire</Heading5>
                        <QuestionSelection questions={questions} 
                            onQuestionSelectionEvent={(selections, hasUnansweredQuestions) => this.onQuestionSelectionEvent(selections, hasUnansweredQuestions)} />
                        <OSUButton ariaLabel={`Cancel questionnaire and go back to job profile ${this.state.jobProfileId}`} color="gray" className="mr-1" path={`/job-profiles/${this.state.jobProfileId}`}>Cancel</OSUButton>
                        <OSUButton ariaLabel={`Submit questionnaire for job profile ${this.state.jobProfileId}`} color="blue" disabled={this.state.submitButtonDisabled} onClick={() => this.submitQuestionnaire()}>Submit</OSUButton>
                    </div>
                );
            }
        }

        const renderPageContent = () => {
            if(this.props.jobProfilesError || this.props.questionsError) { // Questionnaire Error 
                return (<OSUError text="Questionnaire cannot be loaded at this time." actionText="Reload" ariaLabel="Reload questionnaire" onClick={() => this.loadQuestionnaire()} />);
            } else if(this.props.jobProfilesLoading || this.props.questionsLoading) { // Questionnaire Loading
                return (<OSULoading text="Loading Questionnaire..." />);
            } else if(this.props.submitJobProfileQuestionnaireError) { // Submit Questionnaire Error
                return (<OSUError text="An error occurred while processing the questionnaire." ariaLabel="Resubmit questionnaire" actionText="Re-Submit" onClick={() => this.reSubmitQuestionnaire()} />);
            } else if(this.props.submitJobProfileQuestionnaireProcessing) { // Submit Questionnaire Processing
                return (<OSULoading text="Processing Questionnaire..." />);
            } else {
                const jobProfile = this.props.jobProfiles[0];
                if(!jobProfile) {
                    return (<OSUError text={`Job Profile ${this.state.jobProfileId} cannot be found.`} ariaLabel="Navigate to job profiles" actionText="Go to Job Profiles" path="/job-profiles" />);
                } else {
                    document.title = `${this.state.pageTitle} - ${jobProfile.name}`;
                    return (
                        <div ref={(el) => {this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                            <Heading4 dataTestId="pageHeading" id="pageHeader" className="mb-2">{jobProfile.name}</Heading4>
                            <hr />
                            {renderQuestionnaire()}
                        </div>
                    );
                }
            }
        };

        return renderPageContent();
    }
}