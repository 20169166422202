import { getActionState, QUESTIONS, QUESTIONS_ERROR, QUESTIONS_LOADING, QUESTION_SEARCH_QUERY,
    SUBMIT_QUESTION_ERROR, SUBMIT_QUESTION_PROCESSING, SUBMIT_QUESTION_SUCCESS, SUBMIT_QUESTION_DUPLICATE } from '../actions-index';

export function questions(state = [], action = {}) {
    return getActionState(action, [QUESTIONS], state);
}

export function questionsError(state = false, action = {}) {
    return getActionState(action, [QUESTIONS_ERROR], state);
}

export function questionsLoading(state = false, action = {}) {
    return getActionState(action, [QUESTIONS_LOADING], state);
}

export function questionSearchQuery(state = "", action = {}) {
    return getActionState(action, [QUESTION_SEARCH_QUERY], state);
}

export function submitQuestionDuplicate(state = false, action = {}) {
    return getActionState(action, [SUBMIT_QUESTION_DUPLICATE], state);
}

export function submitQuestionError(state = false, action = {}) {
    return getActionState(action, [SUBMIT_QUESTION_ERROR], state);
}

export function submitQuestionProcessing(state = false, action = {}) {
    return getActionState(action, [SUBMIT_QUESTION_PROCESSING], state);
}

export function submitQuestionSuccess(state = false, action = {}) {
    return getActionState(action, [SUBMIT_QUESTION_SUCCESS], state);
}