import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { findJobProfileQuestionnaire, findJobProfiles, updateBreadcrumbTrail } from '../../actions-index';
import JobProfileQuestionnaire from '../components/Item';

const mapStateToProps = (state, props) => {
    return {
        jobProfileQuestionnaire : state.jobProfileQuestionnaire,
        jobProfileQuestionnaireError: state.jobProfileQuestionnaireError,
        jobProfileQuestionnaireLoading: state.jobProfileQuestionnaireLoading,
        jobProfiles: state.jobProfiles,
        jobProfilesError: state.jobProfilesError,
        jobProfilesLoading: state.jobProfilesLoading,
        props
    }; 
};

const mapDispatchToProps = (dispatch) => {
    return {
        findJobProfileQuestionnaire: (jobProfileId) => dispatch(findJobProfileQuestionnaire(jobProfileId)),
        findJobProfiles: (id) => dispatch(findJobProfiles(id)),
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobProfileQuestionnaire));