import { getActionState, JOB_PROFILES, JOB_PROFILES_ERROR, JOB_PROFILES_LOADING, JOB_PROFILE_SEARCH_QUERY,
    JOB_PROFILE_QUESTIONNAIRE, JOB_PROFILE_QUESTIONNAIRE_ERROR, JOB_PROFILE_QUESTIONNAIRE_LOADING,
    SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS, SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR,
    SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING } from '../actions-index';

export function jobProfiles(state = [], action = {}) {
    return getActionState(action, [JOB_PROFILES], state);
}

export function jobProfilesError(state = false, action = {}) {
    return getActionState(action, [JOB_PROFILES_ERROR], state);
}

export function jobProfilesLoading(state = false, action = {}) {
    return getActionState(action, [JOB_PROFILES_LOADING], state);
}

export function jobProfileSearchQuery(state = "", action = {}) {
    return getActionState(action, [JOB_PROFILE_SEARCH_QUERY], state);
}

export function jobProfileQuestionnaire(state = null, action = {}) {
    return getActionState(action, [JOB_PROFILE_QUESTIONNAIRE], state);
}

export function jobProfileQuestionnaireError(state = false, action = {}) {
    return getActionState(action, [JOB_PROFILE_QUESTIONNAIRE_ERROR], state);
}

export function jobProfileQuestionnaireLoading(state = false, action = {}) {
    return getActionState(action, [JOB_PROFILE_QUESTIONNAIRE_LOADING], state);
}

export function submitJobProfileQuestionnaireError(state = false, action = {}) {
    return getActionState(action, [SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR], state);
}

export function submitJobProfileQuestionnaireProcessing(state = false, action = {}) {
    return getActionState(action, [SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING], state);
}

export function submitJobProfileQuestionnaireSuccess(state = false, action = {}) {
    return getActionState(action, [SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS], state);
}
 