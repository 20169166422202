import { API } from "aws-amplify";
import { Auth } from 'aws-amplify';
import {
    buildAction, JOB_PROFILES, JOB_PROFILES_ERROR, JOB_PROFILES_LOADING, JOB_PROFILE_SEARCH_QUERY,
    JOB_PROFILE_QUESTIONNAIRE, JOB_PROFILE_QUESTIONNAIRE_ERROR, JOB_PROFILE_QUESTIONNAIRE_LOADING,
    SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS, SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR,
    SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING
} from '../actions-index';

const apiName = "ReferenceManagement";
const application = "REFERENCE";

export function findJobProfiles(id) {
    return (dispatch) => {
        dispatch(buildAction(JOB_PROFILES_ERROR, false)); // reset from previous requests
        // let errorHandler = () => dispatch(buildAction(JOB_PROFILES_ERROR, true));
        let errorHandler = (error) => {
            console.error("Update findJobProfiles Error:", error);
            dispatch(buildAction(JOB_PROFILES_ERROR, true));
        };
        dispatch(buildAction(JOB_PROFILES_LOADING, true));
        let url = "/job/profiles";
        if (id) {
            url += `?id=${id}`;
        }
        API.get(apiName, url, { response: true })
            .then(
                (response) => {
                    console.log("Response:", response);
                    if (response.status !== 200 || response.status === 401) {
                        if (response.status === 401 || (response.response !== undefined && response.response.status === 401)) {
                            Auth.signOut();
                        }
                    } else {
                        dispatch(buildAction(JOB_PROFILES, response.data));
                    }
                },
                (error) => {
                    errorHandler(error);
                }
            )
            .catch(error => {
                console.log("Error:", error);
                dispatch(buildAction(JOB_PROFILES_ERROR, true));
                // console.error("errorHandler1:",errorHandler1);
            })
            .finally(() => dispatch(buildAction(JOB_PROFILES_LOADING, false)));
    }
}

export function setJobProfileSearchQuery(searchQuery) {
    return (dispatch) => {
        dispatch(buildAction(JOB_PROFILE_SEARCH_QUERY, searchQuery));
    }
}

export function findJobProfileQuestionnaire(jobProfileId) {
    return (dispatch) => {
        let errorHandler = () => {
            dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE_LOADING, false));
            dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE_ERROR, true));
        }

        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE_LOADING, true));

        // find the questionnaire id using the job profile id
        console.log("calling: ", `/job/profile/${jobProfileId}/quiz/id`);
        API.get(apiName, `/job/profile/${jobProfileId}/quiz/id`)
            .then(
                (quizId) => {
                    if (quizId == null) {
                        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE_LOADING, false));
                        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE, null));
                    } else {
                        // find the questionnaire by id
                        API.get(apiName, `/getquiz/${application}/${quizId}`)
                            .then(
                                (response) => {
                                    console.log("findJobProfileQuestionnaire data: ", response);
                                    if (response.httpStatusCode !== "200" || response.httpStatusCode === "401") {
                                        if (response.httpStatusCode === "401" || (response.response !== undefined && response.response.status === 401)) {
                                            Auth.signOut();
                                        }
                                    } else {
                                        let quiz = response.data && response.data.quiz ? response.data.quiz : null;
                                        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE_LOADING, false));
                                        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE, quiz));
                                    }
                                },
                                errorHandler
                            )
                            .catch(errorHandler)
                    }
                },
                errorHandler
            )
            .catch(errorHandler)
    }
}

export function submitJobProfileQuestionnaire(jobProfileId, questionnaire) {
    return (dispatch) => {
        let errorHandler = () => {
            dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR, true));
            dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING, false));
        }

        dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING, true));
        API.post(apiName, "/createquiz", { body: questionnaire }) // create the questionnaire
            .then(
                (response) => {
                    API.post(apiName, `/job/profile/${jobProfileId}/quiz/${response.quiz.quizId}`) // associate the questionnaire with the job profile
                        .then(
                            () => {
                                dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS, true));
                                dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING, false));
                            },
                            errorHandler
                        )
                        .catch(errorHandler)
                },
                errorHandler
            )
            .catch(errorHandler);
    }
}

export function resetSubmitJobProfileQuestionnaireFlags() {
    return (dispatch) => {
        dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR, false));
        dispatch(buildAction(SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS, false));
        dispatch(buildAction(JOB_PROFILE_QUESTIONNAIRE, null));
    }
}