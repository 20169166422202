/* App */
export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const UPDATE_BREADCRUMB_TRAIL = 'UPDATE_BREADCRUMB_TRAIL';

/* Postings */
export const POSTINGS_FETCH_DATA = 'POSTINGS_FETCH_DATA';
export const POSTINGS_HAS_ERROR = 'POSTINGS_HAS_ERROR';
export const POSTINGS_LIST_IS_LOADING = 'POSTINGS_LIST_IS_LOADING';
export const UPDATE_POSTING_ITEM = 'UPDATE_POSTING_ITEM'
export const POSTING_IS_LOADING = 'POSTING_IS_LOADING';
export const POSTING_ITEM_HAS_ERROR = 'POSTING_ITEM_HAS_ERROR';
export const POSTING_ITEM_FETCH_DATA = 'POSTING_ITEM_FETCH_DATA';
export const CANDIDATES_FETCH_DATA = 'CANDIDATES_FETCH_DATA';
export const CANDIDATES_IS_LOADING = 'CANDIDATES_IS_LOADING';
export const CANDIDATES_HAS_ERROR = 'CANDIDATES_HAS_ERROR';
export const REFERENCES_FETCH_DATA = 'REFERENCES_FETCH_DATA';
export const REFERENCES_LOADING = 'REFERENCES_LOADING';
export const REFERENCES_HAS_ERROR = 'REFERENCES_HAS_ERROR';
export const UPDATE_POSTINGS_SEARCH = 'UPDATE_POSTINGS_SEARCH';
export const UPDATE_CANDIDATES_SEARCH = 'UPDATE_CANDIDATES_SEARCH';

/* Collection */
export const JOB_FAMILY_FETCH_DATA = 'JOB_FAMILY_FETCH_DATA';
export const SUBMIT_FORM_JOB_PROFILE_TYPE = 'SUBMIT_FORM_JOB_PROFILE_TYPE';
export const SUBMIT_CANDIDATE_DATA = 'SUBMIT_CANDIDATE_DATA';
export const SUBMIT_REFERENCE_TYPE = 'SUBMIT_REFERENCE_TYPE';
export const SUBMIT_VERIFIED_FORM = 'SUBMIT_VERIFIED_FORM';
export const UPDATE_JOB_PROFILES_SEARCH = 'UPDATE_JOB_PROFILES_SEARCH';
export const UPDATE_JOB_FAMILY_FILTER = 'UPDATE_JOB_FAMILY_FILTER';
export const SEND_COLLECTION_ERROR = 'SEND_COLLECTION_ERROR';
export const SEND_COLLECTION_PROCESSING = 'SEND_COLLECTION_PROCESSING';
export const SEND_COLLECTION_SUCCESS = 'SEND_COLLECTION_SUCCESS';
export const SEND_COLLECTION_MESSAGE = 'SEND_COLLECTION_MESSAGE';
export const SUBMIT_DEFAULT_QUESTIONNAIRE = 'SUBMIT_DEFAULT_QUESTIONNAIRE';

/* Job Profiles */
export const JOB_PROFILES = 'JOB_PROFILES';
export const JOB_PROFILES_ERROR = 'JOB_PROFILES_ERROR';
export const JOB_PROFILES_LOADING = 'JOB_PROFILES_LOADING';
export const JOB_PROFILE_SEARCH_QUERY = 'JOB_PROFILE_SEARCH_QUERY';
export const JOB_PROFILE_QUESTIONNAIRE = 'JOB_PROFILE_QUESTIONNAIRE';
export const JOB_PROFILE_QUESTIONNAIRE_ERROR = 'JOB_PROFILE_QUESTIONNAIRE_ERROR';
export const JOB_PROFILE_QUESTIONNAIRE_LOADING = 'JOB_PROFILE_QUESTIONNAIRE_LOADING';
export const SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS = 'SUBMIT_JOB_PROFILE_QUESTIONNAIRE_SUCCESS';
export const SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR = 'SUBMIT_JOB_PROFILE_QUESTIONNAIRE_ERROR';
export const SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING = 'SUBMIT_JOB_PROFILE_QUESTIONNAIRE_PROCESSING';

/* Questions */
export const QUESTIONS = 'QUESTIONS';
export const QUESTIONS_ERROR = 'QUESTIONS_ERROR';
export const QUESTIONS_LOADING = 'QUESTIONS_LOADING';
export const QUESTION_SEARCH_QUERY = 'QUESTION_SEARCH_QUERY';
export const SUBMIT_QUESTION_DUPLICATE = 'SUBMIT_QUESTION_DUPLICATE';
export const SUBMIT_QUESTION_ERROR = 'SUBMIT_QUESTION_ERROR';
export const SUBMIT_QUESTION_PROCESSING = 'SUBMIT_QUESTION_PROCESSING';
export const SUBMIT_QUESTION_SUCCESS = 'SUBMIT_QUESTION_SUCCESS';
export const SEND_COLLECTION_CLEARED = 'SEND_COLLECTION_CLEARED';

/* Reference */
export const UPDATE_REFERENCE_SUCCESS = 'UPDATE_REFERENCE';
export const UPDATE_REFERENCE_ERROR = 'UPDATE_REFERENCE_ERROR';
export const UPDATE_REFERENCE_PROCESSING = 'UPDATE_REFERENCE_PROCESSING';
export const REFERENCE_TOKEN_STATUS = 'REFERENCE_TOKEN_STATUS';
export const REFERENCE_SUBMISSION_LINK = 'REFERENCE_SUBMISSION_LINK';

/* Export each action file below */
export * from './App/actions';
export * from './JobProfiles/actions';
export * from './Postings/actions';
export * from './Questions/actions';
export * from './Collection/actions';
export * from './Reference/actions';
export * from './Candidates/actions';

export function buildAction(type, payload) {
    return { type, payload };
}

export function getActionState(action, types, stateDefault) {
    let payload = stateDefault;
    if(action && action.type && types && types.length && types.length > 0) {
        for(let i = 0; i < types.length; i++) {
            if(action.type === types[i]) {
                payload = action.payload;
                break;
            }
        }
    }
    return payload;
}
