import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Collection from '../components/';
import { submitDefaultQuestionnaire, findJobProfiles, updateBreadcrumbTrail, submitCandidates, submitReferenceType, submitFormJobProfile, submitVerifiedForm, updateJobProfilesSearch, updateJobFamilyFilter, sendCollection } from '../../actions-index';

const mapStateToProps = (state) => {
    return {
        jobProfiles: state.jobProfiles,
        searchQuery: state.jobProfilesSearchQuery,
        jobFamilyFilter: state.jobFamilyFilter,
        jobProfilesError: state.jobProfilesError,
        jobProfilesLoading: state.jobProfilesLoading,
        candidatesIsLoading: state.candidatesIsLoading,
        defaultQuestionnaire: state.defaultQuestionnaire,
        collection: {
            candidates: state.pendingCandidates,
            referenceType: state.pendingReferenceType,
            jobProfile: state.pendingFormJobProfile,
            verifiedForm: state.verifiedForm
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        findJobProfiles: () => dispatch(findJobProfiles()),
        submitCandidates: (candidates) => dispatch(submitCandidates(candidates)),
        updateReferenceType: (referenceType) => dispatch(submitReferenceType(referenceType)),
        updateFormJobProfile: (jobProfile) => dispatch(submitFormJobProfile(jobProfile)),
        updateDefaultQuestionnaire: (value) => dispatch(submitDefaultQuestionnaire(value)),
        updateSearch: (query) => dispatch(updateJobProfilesSearch(query)),
        updateFilterByFamily: (family) => dispatch(updateJobFamilyFilter(family)),
        updateVerifiedForm: (values) => dispatch(submitVerifiedForm(values)),
        sendCollection: (collection) => dispatch(sendCollection(collection))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collection));