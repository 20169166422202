import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Candidates from '../components/';
import { updateCandidatesSearch, updateBreadcrumbTrail, candidatesFetchData, referencesFetchData, submitCandidates } from '../../actions-index';
// import { updateCandidatesSearch } from "../actions";
// import { submitCandidates } from '../../actions/collection';
// import { referencesFetchData } from '../../actions/references'

const mapStateToProps = (state) => {
    return {
        candidates: state.candidates,
        activeReferences: state.references,
        pendingCandidates: state.pendingCandidates,
        searchQuery: state.candidatesSearchQuery,
        candidatesIsLoading: state.candidatesIsLoading,
        candidatesHasError: state.candidatesHasError,
        referencesIsLoading: state.referencesLoading,
        referencesHasError: state.referencesHasError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        fetchCandidates: (postingId) => dispatch(candidatesFetchData(postingId)),
        fetchReferences: (postingId, candidateId, test) => dispatch(referencesFetchData(postingId, candidateId, test)),
        submitCandidates: (candidates) => dispatch(submitCandidates(candidates)),
        updateSearch: (query) => dispatch(updateCandidatesSearch(query)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Candidates));