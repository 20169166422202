import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PostingsList from '../components/List';
import { postingsFetchData, updatePostingsSearch, updatePostingItem } from '../actions';

const mapStateToProps = (state) => {
    return {
        postings: state.postings,
        postingsHasError: state.postingsHasError,
        // postingIsLoading: state.postingIsLoading,
        jobPosting: state.postingItem,
        isLoading: [state.postingListIsLoading].some(Boolean),
        searchQuery: state.postingsSearchQuery
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPostings: () => dispatch(postingsFetchData()),
        updateSearch: (query) => dispatch(updatePostingsSearch(query)),
        setPostingItem: (posting) => dispatch(updatePostingItem(posting))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostingsList));
