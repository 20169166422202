import React, { Component } from "react";
import { Body2, Heading4, Heading6, Icon, OSUButton } from "osu-react-components";
import { OSULoading, QuestionView } from 'osu-react-components';
import { Alert, Form, FormFeedback, FormGroup, Input, InputGroup, Label, CustomInput } from 'reactstrap';
import Select from 'react-select';
import * as _ from "lodash";

const answersLimit = 10;
const application = "REFERENCE";

export default class Question extends Component {
    constructor(props) {
        super(props);
        let locationState = this.props.location.state || {};
        let editQuestion = locationState.question || {};
        let isEdit = typeof locationState.question !== "undefined";
        this.state = {
            pageTitle: `${isEdit ? "Edit" : "Create"} Question`,
            isEdit,
            editQuestion,
            formId: "questionForm",
            formInputInvalid: {},
            formSubmitDisabled: true,
            questionTypeSelectOptions: [
                { label: "Text", value: "text" },
                { label: "Radio", value: "radio" },
                { label: "Checkbox", value: "checkbox" }
            ],
            answers: [],
            focusElement: null,
            submitQuestion: null,
            isDisabled: editQuestion.disabled === true ? true : false
        };
        let questionTypeSelectedOption = this.state.questionTypeSelectOptions[0]; // default
        if (isEdit) {
            let editQuestionTypeSelectedOption = _.find(this.state.questionTypeSelectOptions, ["value", editQuestion.questionType]);
            if (typeof editQuestionTypeSelectedOption !== "undefined") {
                questionTypeSelectedOption = editQuestionTypeSelectedOption
            }
            if (editQuestion.answers) {
                editQuestion.answers.forEach((answer, index) => {
                    this.state.answers.push(answer.title);
                    this.state.formInputInvalid[this.buildAnswerKey(index)] = false;
                });
            }
        }
        this.state.questionTypeSelectedOption = questionTypeSelectedOption;
    }

    componentWillMount() {
        document.title = this.state.pageTitle;
    }

    componentDidMount() {
        this.pageContent.focus();
    }

    componentDidUpdate(prevProps, prevState) {
        // clear answers when changing the question type to Text
        if (prevState.questionTypeSelectedOption.value !== "text" && this.state.questionTypeSelectedOption.value === "text") {
            this.setState({ answers: [] });
        }

        // add a new empty answer and set the focus when changing the question type from Text
        if (prevState.questionTypeSelectedOption.value === "text" && this.state.questionTypeSelectedOption.value !== "text") {
            this.setState({ answers: [""], focusElement: this.buildAnswerKey(0) });
        }

        // set the focus, if necessary
        let focusElement = this.state.focusElement;
        if (focusElement !== null) {
            let element = document.getElementById(focusElement);
            if (element) {
                element.focus();
                this.setState({ focusElement: null });
            }
        }
    }

    componentWillUnmount() {
        this.props.resetSubmitQuestionFlags();
    }

    addAnswer = () => {
        let answers = this.state.answers;
        answers.push(""); // add a new empty answer
        this.setState({
            answers,
            focusElement: this.buildAnswerKey(answers.length - 1), // save the name of the new answer to focus on with the next render
            formSubmitDisabled: true
        });
    }

    buildAnswerKey = (answerIndex) => {
        return `answers[${answerIndex}]`;
    }

    buildSubmitQuestion(form) {
        let formData = new FormData(form);

        let question = {};
        if (this.state.isEdit) {
            question.id = formData.get("id");
        }
        question.idType = "QUESTION";
        question.application = formData.get("application");
        question.title = formData.get("title");
        question.sortOrder = 1;
        question.questionType = formData.get("questionType");
        question.disabled = formData.get("isDisabled") === "on" ? true : false;
        if (question.questionType !== "text") {
            question.answers = [];
            for (let i = 0; i < answersLimit; i++) {
                let answer = formData.get(`answers[${i}]`);
                if (answer) {
                    question.answers.push({
                        title: answer,
                        value: i + 1,
                        valid: true,
                        sortOrder: i + 1
                    });
                }
            }
        }
        return question;
    }

    isFormValid = () => {
        let form = document.getElementById(this.state.formId);
        return form.checkValidity();
    }

    moveAnswerUp = (index) => {
        if (index !== 0) {
            let answers = [...this.state.answers];
            let answer = answers[index];
            answers[index] = answers[index - 1];
            answers[index - 1] = answer;
            this.setState({
                answers,
                formSubmitDisabled: !this.isFormValid()
            });
        }
    }

    onAnswerChange = (event, index) => {
        this.onInputChange(event);
        let answers = [...this.state.answers];
        answers[index] = event.target.value;
        this.setState({ answers });
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        let form = event.target;
        if (form.checkValidity()) {
            let submitQuestion = this.buildSubmitQuestion(form);
            console.log("submitQuestion: ", submitQuestion);
            this.setState({ submitQuestion }); // store for confirmation
            this.props.submitQuestion(submitQuestion);
        }
    }

    onInputChange = (event) => {
        let input = event.target;
        input.value = _.upperFirst(input.value); // capitalize the first word
        this.setInputInvalid(input);
    }

    onQuestionTypeSelectChange = (selectedOption) => {
        this.setState(prevState => {
            let willShowAnswers = (prevState.questionTypeSelectedOption.value === "text" && selectedOption.value !== "text");
            let isFormInvalid = willShowAnswers || !this.isFormValid();
            return {
                formSubmitDisabled: isFormInvalid,
                questionTypeSelectedOption: selectedOption
            }
        });
    }

    removeAnswer = (answerIndex) => {
        let answers = this.state.answers;
        answers.splice(answerIndex, 1); // remove the answer

        let answerName = this.buildAnswerKey(answerIndex);
        let formInputInvalid = this.state.formInputInvalid;
        delete formInputInvalid[answerName]; // remove the invalid input flag, if it is set

        this.setState({
            answers,
            focusElement: this.buildAnswerKey(answerIndex - 1), // save the name of the previous answer to focus on with the next render
            formInputInvalid,
            formSubmitDisabled: !this.isFormValid()
        });
    }

    setInputInvalid = (input) => {
        let formInputInvalid = this.state.formInputInvalid;
        let inputInvalid = (input.value.trim() === "" ? true : !input.checkValidity()); // prevent only spaces
        formInputInvalid[input.name] = inputInvalid;

        let isFormInvalid = inputInvalid || !this.isFormValid();

        this.setState({
            formInputInvalid,
            formSubmitDisabled: isFormInvalid
        });
    }

    toggleIsDisabled = () => {
        this.setState({
            isDisabled: this.state.isDisabled === true ? false : true,
            formSubmitDisabled: false
        });
    }
    render() {
        const incompleteAnswers = _.filter(this.state.answers, answer => { return answer === ""; });
        const Answers = this.state.answers.map((answer, index) => {
            let key = this.buildAnswerKey(index);
            let displayIndex = index + 1;
            let isFirstAnswer = (index === 0);
            let isInputValid = this.state.formInputInvalid[key] === false;
            let isPrevInputValid = isFirstAnswer ? true : this.state.formInputInvalid[this.buildAnswerKey(index - 1)] === false;
            return (
                <FormGroup key={key}>
                    <Label for={key}>Answer {displayIndex}</Label>
                    <InputGroup>
                        <Input type="text" id={key} name={key} value={answer} required invalid={this.state.formInputInvalid[key]}
                            onChange={(e) => this.onAnswerChange(e, index)} />
                        <OSUButton ariaLabel="Remove Answer" link color="red" className={isFirstAnswer ? "invisible" : ""}
                            disabled={isFirstAnswer}
                            onClick={(e) => { e.preventDefault(); this.removeAnswer(index); }}>
                            <Icon type="times" color="red" size="lg" />
                        </OSUButton>
                        <OSUButton ariaLabel="Move Answer Up" link color="blue" className={isFirstAnswer ? "invisible" : ""}
                            disabled={(isFirstAnswer || !isInputValid || !isPrevInputValid)}
                            onClick={(e) => { e.preventDefault(); this.moveAnswerUp(index); }}>
                            <Icon type="level-up" color="blue" size="lg" />
                        </OSUButton>
                        <FormFeedback>Answer {displayIndex} is required{displayIndex > 1 && " (or can be removed)"}</FormFeedback>
                    </InputGroup>
                </FormGroup>
            );
        });

        const QuestionForm = (
            <div>
                {this.props.submitQuestionProcessing &&
                    <OSULoading text="Processing Question..." />
                }
                {this.props.submitQuestionError &&
                    <Alert color="danger">An error occurred while processing the question.</Alert>
                }
                {this.props.submitQuestionDuplicate &&
                    <Alert color="danger">This question already exists.</Alert>
                }
                <Form id={this.state.formId} noValidate onSubmit={this.onFormSubmit}
                    className={this.props.submitQuestionProcessing ? "d-none" : ""}>

                    {this.state.isEdit &&
                        <Input type="hidden" name="id" value={this.state.editQuestion.id} />
                    }
                    <Input type="hidden" name="application" value={this.state.editQuestion.application || application} />
                    <FormGroup>
                        <Label for="title">Question</Label>
                        <Input type="text" name="title" defaultValue={this.state.editQuestion.title || ""} required invalid={this.state.formInputInvalid.title} onChange={this.onInputChange} />
                        <FormFeedback>Question is required</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="questionType">Type</Label>
                        <Select name="questionType" options={this.state.questionTypeSelectOptions}
                            value={this.state.questionTypeSelectedOption} onChange={this.onQuestionTypeSelectChange} />
                    </FormGroup>
                    {Answers}
                    {Answers.length > 0 &&
                        <div className="d-block mb-4">
                            <OSUButton ariaLabel="Add Answer" link color="blue" disabled={incompleteAnswers.length !== 0} onClick={() => this.addAnswer()}>
                                <Icon type="plus" color="blue" size="lg" /> Add Answer
                            </OSUButton>
                        </div>
                    } 
                    <div class="d-flex flex-row">
                    <OSUButton ariaLabel="Cancel and navigate to postings" color="gray" className="p-2" path="/questions">Cancel</OSUButton>
                    <OSUButton ariaLabel="Submit questionnaire" name="submission" value="save" className="p-2" type="submit" color="blue" disabled={this.state.formSubmitDisabled}>Submit</OSUButton>
                   
                        <CustomInput className="p-2 ml-auto" id="isDisabled" name="isDisabled" onColor="danger" type="switch" label="Disabled" defaultChecked={this.state.isDisabled} onChange={this.toggleIsDisabled} ></CustomInput>
                    </div>
                    <br />

                </Form>
            </div>
        );

        const SubmitQuestionConfirmation = (
            <div>
                <Heading6 className="mt-4 mb-2">Confirmation</Heading6>
                <hr />
                <Body2>
                    The question was successfully {this.state.isEdit ? "edited" : "created"}.
                </Body2>
                <hr />
                <QuestionView question={this.state.submitQuestion} className="mb-4" />
                <OSUButton ariaLabel="Navigate to questions" outline uppercase color="gray" path="/questions">Go to Questions</OSUButton>
            </div>
        );

        const renderPageContent = () => {
            if (this.props.submitQuestionSuccess) {
                return SubmitQuestionConfirmation;
            } else {
                return QuestionForm;
            }
        }

        return (
            <div ref={(el) => { this.pageContent = el; }} tabIndex="-1" aria-labelledby="pageHeader">
                <Heading4 id="pageHeader" className="mb-2">{this.state.pageTitle}</Heading4>
                {renderPageContent()}
            </div>
        );
    }
}