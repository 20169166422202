import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Reference from '../components/';
import { updateBreadcrumbTrail, updateReference, resetUpdateReferenceFlags, generateReferenceToken } from '../../actions-index';
// import { submitCandidates } from '../../actions/collection';
// import { referencesFetchData } from '../../actions/references'

const mapStateToProps = (state, props) => {
    return {
        updateReferenceSuccess: state.updateReferenceSuccess,
        updateReferenceError: state.updateReferenceError,
        updateReferenceProcessing: state.updateReferenceProcessing,
        submissionLink: state.referenceSubmissionLink,
        referenceTokenLoading: state.referenceTokenStatus === 'loading',
        referenceTokenError: state.referenceTokenStatus === 'error',
        referencesLoading: state.referencesLoading,
        props
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBreadcrumbTrail: (pathObjs) => dispatch(updateBreadcrumbTrail(pathObjs)),
        updateReference: (reference) => dispatch(updateReference(reference)),
        resetUpdateReferenceFlags: () => dispatch(resetUpdateReferenceFlags()),
        generateReferenceToken: (postingId, candidateId, referenceId) => dispatch(generateReferenceToken(postingId, candidateId, referenceId))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reference));