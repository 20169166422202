import React from 'react';
import ConfirmCandidates from './ConfirmCandidates'
import SelectJobProfile from './SelectJobProfile'
import SendCollection from '../containers/SendCollection'
import FormReview from '../containers/FormReview'
import { OSUButton, Heading4, OSUError, Stepper, StepperSection } from 'osu-react-components';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';


class Collection extends React.Component {
    constructor() {
        super();

        this.state = {
            componentId: 0,
            referenceTypeSelected: null
        }
        this.executeStepper = this.executeStepper.bind(this);
        this.clearData = this.clearData.bind(this)
    }

    componentDidMount() {
        const postingId = this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') &&
            this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null;
        if (!isEmpty(postingId)) {
            this.props.updateBreadcrumbTrail([
                {
                    path: '/',
                    text: 'postings'
                },
                {
                    path: `/postings/${postingId}`,
                    text: postingId
                },
                {
                    path: `/create-collection`,
                    text: 'Create Collection',
                    active: true
                }
            ]);
        }
    }

    componentWillUnmount() {
        this.clearData()
    }

    clearData() {
        this.props.updateBreadcrumbTrail([]);
        this.props.updateFormJobProfile({});
        this.props.updateReferenceType('');
        this.props.submitCandidates([]);
        this.props.updateSearch('');
        this.props.updateFilterByFamily('');
        this.props.updateVerifiedForm({});
        this.props.sendCollection({})
    }

    decrementComponentId() {
        const { componentId } = this.state
        this.setState({ componentId: componentId > 0 ? componentId - 1 : componentId })
    }

    renderPageStepper(type, verifiedCandidates, verifiedPosting) {
        const confirmationValues = {
            candidates: verifiedCandidates,
            pendingCandidates: this.props.collection.candidates,
        }
        const confirmationButton = (disabled = false, customFunction = null) => <OSUButton ariaLabel="Next" disabled={disabled} onClick={this.executeStepper.bind(this, { ...confirmationValues, customFunction })}>{this.state.componentId === 3 ? 'Send' : 'Next'}</OSUButton>
        const previousButton = (disabled = false) => <OSUButton ariaLabel="Previous" className="mr-2" link uppercase={false} disabled={disabled} onClick={() => this.decrementComponentId()}>Previous</OSUButton>

        if (type === 'QUESTIONNAIRE') {
            const headings = ["Select Job Profile", "Review Questionnaire", "Select Candidates", "Send for Collection"]
            return <Stepper component={this.renderFormStepperComponent(confirmationButton, previousButton, verifiedPosting)} heading={headings[this.state.componentId]}>
                {headings.map((section, index) =>
                    <StepperSection key={index} current={this.state.componentId === index} completed={this.state.componentId > index} circleText={index + 1} heading={section} />
                )}
            </Stepper>
        } else if (type === 'LETTER') {
            const headings = ["Select Candidates", "Send for Collection"]

            return <Stepper component={this.renderLetterStepperComponent(confirmationButton, previousButton, verifiedPosting)} heading={headings[this.state.componentId]}>
                {headings.map((section, index) =>
                    <StepperSection key={index} current={this.state.componentId === index} completed={this.state.componentId > index} circleText={index + 1} heading={section} />
                )}
            </Stepper>
        }
    }

    renderLetterStepperComponent(confirmationButton, previousButton, verifiedPosting) {
        switch (this.state.componentId) {
            case 0:
                return <ConfirmCandidates posting={verifiedPosting} screenSize={this.props.screenSize} confirmationButton={confirmationButton} candidatesIsLoading={this.props.candidatesIsLoading} pendingCandidates={this.props.collection.candidates} />
            case 1:
                return <SendCollection clearData={this.clearData} posting={verifiedPosting} disableBreadcrumbs previousButton={previousButton} confirmationButton={confirmationButton} />
            default:
                return <div />
        }
    }

    renderFormStepperComponent(confirmationButton, previousButton, verifiedPosting) {
        switch (this.state.componentId) {
            case 0:
                return <SelectJobProfile {...this.props} confirmationButton={confirmationButton} />
            case 1:
                return <FormReview {...this.props} confirmationButton={confirmationButton} previousButton={previousButton} />
            case 2:
                return <ConfirmCandidates posting={verifiedPosting} screenSize={this.props.screenSize} confirmationButton={confirmationButton} previousButton={previousButton} pendingCandidates={this.props.collection.candidates} candidatesIsLoading={this.props.candidatesIsLoading} />
            case 3:
                return <SendCollection clearData={this.clearData} posting={verifiedPosting} disableBreadcrumbs confirmationButton={confirmationButton} previousButton={previousButton} />
            default:
                return <div />
        }
    }

    executeStepper(values) {
        const { candidates, pendingCandidates, customFunction } = values
        !!customFunction && typeof customFunction === 'function' && customFunction()

        this.setState({ componentId: this.state.componentId + 1 })
        if (candidates.length > 0 && pendingCandidates.length > 0) {
            const properCandidateData = pendingCandidates.map(pendingCandidate => {
                return candidates.filter(candidate => candidate.id === pendingCandidate.id)[0]
            })
            this.props.submitCandidates(properCandidateData)
        }
    }

    render() {
        const postingId = this.props.hasOwnProperty('match') && this.props.match.hasOwnProperty('params') && this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null

        if (isEmpty(this.props) || isEmpty(this.props.location) || isEmpty(this.props.location.state)) {
            return <Redirect to={`/postings/${postingId}`} />
        }

        const { collection } = this.props;
        const verifiedCandidates = this.props.hasOwnProperty('location') && this.props.location.hasOwnProperty('state') && this.props.location.state.hasOwnProperty('candidates')
            && Array.isArray(this.props.location.state.candidates) && this.props.location.state.candidates.length > 0 && this.props.location.state.candidates
        const verifiedPosting = this.props.location.state.hasOwnProperty('posting') && !isEmpty(this.props.location.state.posting) ? this.props.location.state.posting : null

        if (!verifiedPosting || !collection.referenceType) {
            return <OSUError ariaLabel={`Navigate to Posting ${postingId}`} text={`${!verifiedPosting ? 'Posting' : 'Reference type'} could not be verified for this collection.`} actionText='Go to Posting' path={`/postings/${postingId}`} />
        }
        return <div>
            <Heading4 dataTestId="pageHeading" className="mb-2">{verifiedPosting.hasOwnProperty('title') ? verifiedPosting.title : 'No Job Name Found'}</Heading4>
            {this.renderPageStepper(collection.referenceType, verifiedCandidates, verifiedPosting)}
        </div>
    }
}

export default Collection;