import React from 'react';
import { Table, Heading6, Subtitle1, Icon, Overline, OSUError, OSULoading, PaginationWrapper } from 'osu-react-components';
import * as _ from 'lodash';
import { CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText, Label, FormGroup } from 'reactstrap';
import '../collection.css';
import Select from 'react-select';

class SelectJobProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            dataIndex: 0,
            isDefaultQuestionnaire: true,
            selectedDefaultQuestionnaire: {value: false},
            defaultSelected: false
        }
        // this.updateReferenceType = this.updateReferenceType.bind(this);
        this.updateFormWithJobProfile = this.updateFormWithJobProfile.bind(this);
        this.searchJobProfiles = this.searchJobProfiles.bind(this);
        this.filterJobProfiles = this.filterJobProfiles.bind(this);
    }

    componentDidMount() {
        this.props.findJobProfiles();
        if (this.props.hasOwnProperty('location') && this.props.location.hasOwnProperty('state') && this.props.location.state.hasOwnProperty('posting')) {
            _.isEmpty(this.props.searchQuery) && this.searchJobProfiles(this.props.location.state.posting.jobCode);
        }
    }

    componentDidUpdate() {
        console.log("props: ", this.props);
    }

    setDataIndex = (dataIndex) => {
        this.setState({ dataIndex });
    }
    // updateReferenceType(type) {
    //     this.props.updateReferenceType(type)
    // }

    updateFormWithJobProfile(profile) {
        console.log("updating form with job profile: ", profile);
        this.props.updateFormJobProfile(profile)
        if(profile.familyId) {
            this.props.updateDefaultQuestionnaire(null);
        }
    }

    searchJobProfiles(query = '') {
        this.props.updateSearch(query)
    }

    filterJobProfiles(e) {
        this.props.updateFilterByFamily(!e || !e.value || e.value.length < 1 ? '' : e.value)
    }

    renderJobProfileTable(jobProfiles, searchQuery, jobFamilyFilter) {
        const uniqueFamilies = _.uniqWith(jobProfiles.map(profile => { return { value: profile.familyId, label: profile.familyName } }), _.isEqual)
        const defaultFamily = _.isEmpty(jobFamilyFilter) ? '' : _.find(uniqueFamilies, function (o) { return o.value === jobFamilyFilter })
        let renderableProfiles = jobProfiles
        const dataKeys = [{
            key: "selectButton",
            label: "",
            width: 3
        }, {
            key: "profileName",
            label: "Job Profile",
            width: 62
        }, {
            key: "familyName",
            label: "Job Family",
            width: 35
        }]
        if (!_.isEmpty(jobFamilyFilter) && jobFamilyFilter.length > 0) {
            renderableProfiles = jobProfiles.filter(profile => profile.familyId === jobFamilyFilter)
        }
        let data = Array.isArray(renderableProfiles) ? renderableProfiles.map((profile, profileIndex) => {
            const profileChecked = this.props.collection.jobProfile.id === profile.id

            return {
                selectButton: <CustomInput type="radio" name="select" id={`jobProfile${profileIndex}`} checked={profileChecked} readOnly />,
                profileName: `${profile.name} (${profile.id})`,
                familyName: profile.familyName,
                onRowClick: () => this.updateFormWithJobProfile(profile)
            }
        }) : []
        if (searchQuery && searchQuery.length > 0) {
            const testString = (str = '') => str.toLowerCase().includes(searchQuery.toLowerCase())
            data = data.filter(item => testString(item.profileName))
        }

        if (this.props.collection.referenceType === 'QUESTIONNAIRE') {
            return <div>
                <div className="d-flex mb-4">
                    <InputGroup className="mr-4 w-75" >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <Icon type="search" color="gray" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder='Search Job Profile...' value={searchQuery} onChange={(e) => this.searchJobProfiles(e.target.value)} />
                    </InputGroup>
                    <Select isClearable isSearchable className="w-25" placeholder="Job Family" options={uniqueFamilies} defaultValue={defaultFamily} onChange={(e) => this.filterJobProfiles(e)} />
                </div>
                {data && data.length > 0
                    ? formView(data, dataKeys, this.state.dataIndex, this.setDataIndex)
                    : <Subtitle1>No results found.</Subtitle1>
                }
            </div>
        }
        return null
    }

    setDefaultQuestionnaireFlag(e) {
        console.log("setDefaultQuestionnaire event: ", e.target.value);
        this.setState({
            isDefaultQuestionnaire: e.target.value === "default" ? true : false
        });
    }

    setDefaultQuestionnaireType(e) {
        this.updateFormWithJobProfile({id: e.value});
        console.log("updating default questionnaire: ", e);
        this.props.updateDefaultQuestionnaire(e);
        this.setState({
            defaultSelected: true
        });
    }

    render() {
        const { jobProfiles, searchQuery, jobFamilyFilter, collection } = this.props
        if (this.props.jobProfilesError) {
            return (
                <OSUError ariaLabel="Reload job profiles" text="Job Profiles cannot be loaded at this time." onClick={() => this.props.findJobProfiles()} />
            );
        } else if (this.props.jobProfilesLoading) {
            return <OSULoading text="Loading Job Profiles..." />
        }
        return <div>
            {collection.referenceType && <hr />}

            <fieldset>
                <legend>Select Job Profile or Default Questionnaire</legend>
                <FormGroup check>
                    <Label for="defaultQuestionnaireRadio">
                        <Input type="radio" name="defaultQuestionnaireRadio" defaultChecked={this.state.isDefaultQuestionnaire} value="default" onChange={(e) => this.setDefaultQuestionnaireFlag(e)}/>
                        Default Questionnaire  
                    </Label>  
                    {this.state.isDefaultQuestionnaire &&
                    <Select className="w-25" value={this.props.defaultQuestionnaire && this.props.defaultQuestionnaire}  placeholder="Questionnaire..." options={[{value: "DEFAULT_HIGH_VOLUME", label: "High Volume Reference"},{value: "DEFAULT_PROFESSIONAL", label: "Professional Reference"}]} onChange={(e) => this.setDefaultQuestionnaireType(e)} />}
                    <br />
                    <Label for="defaultQuestionnaireRadio">
                        <Input type="radio" name="defaultQuestionnaireRadio" value="notDefault" onChange={(e) => this.setDefaultQuestionnaireFlag(e)}/>
                        Select Profile Questionnaire
                    </Label>                   
                </FormGroup>
            </fieldset>
            {!this.state.isDefaultQuestionnaire && <div>
            {this.renderJobProfileTable(jobProfiles, searchQuery, jobFamilyFilter)}
            <Heading6 className="mt-4 mb-2">Confirm Job Profile</Heading6>
            {collection.jobProfile && collection.jobProfile.name && <div className="d-flex flex-column flex-sm-row mb-2">
                <Subtitle1 color={!collection.jobProfile || !collection.jobProfile.name ? 'gray' : 'black'} className="mr-5">
                    <Overline className="mb-1" color="gray">Selected</Overline>
                    {collection.jobProfile.name}
                </Subtitle1>
            
            </div>}</div>}
            {this.props.confirmationButton(((!this.state.isDefaultQuestionnaire || !this.props.defaultQuestionnaire) || !this.state.selectedDefaultQuestionnaire) && (!collection.jobProfile || _.isEmpty(collection.jobProfile) || !collection.jobProfile.hasOwnProperty('id') || _.isEmpty(collection.jobProfile.id)))}
        </div>
    }
}

export default SelectJobProfile;

function formView(data, dataKeys, index, setDataIndex) {
    const chunkedData = _.chunk(data, 10)

    if (_.isEmpty(chunkedData)) {
        return <Subtitle1>No results found.</Subtitle1>
    }
    return <PaginationWrapper totalPageCount={chunkedData.length} updateDataIndex={setDataIndex} dataIndex={index}>
        <Table data={chunkedData[index]} dataKeys={dataKeys} />
    </PaginationWrapper>
}