import React, { Component } from 'react';

import { Route } from "react-router-dom";

import OSUNavBar from 'react-osu-navbar';
import Amplify, { Auth } from 'aws-amplify';

import Header from '../../Header/components';
import '../App.css';
import { isEmpty, intersection } from 'lodash';
import JobProfilesListContainer from '../../JobProfiles/containers/List';
import JobProfileItemContainer from '../../JobProfiles/containers/Item';
import JobProfileQuestionnaireContainer from '../../JobProfiles/containers/Questionnaire';
import DefaultQuestionnaireContainer from '../../JobProfiles/containers/DefaultQuestionnaire';
import PostingsListContainer from '../../Postings/containers/List';
import PostingItemContainer from '../../Postings/containers/Item';
import QuestionContainer from '../../Questions/containers/Item';
import QuestionsListContainer from '../../Questions/containers/List';
import CollectionContainer from '../../Collection/containers/collection';
import SendCollectionContainer from '../../Collection/containers/SendCollection';
import ReferenceContainer from '../../Reference/containers/reference';
// import ConfirmationStepperContainer from '../../containers/ConfirmationStepper/ConfirmationStepperContainer';
import SignInContainer from '../../Authentication/containers/SignIn';
import SignOut from '../../Authentication/components/SignOut';
import aws_exports from '../../aws-exports';
import apiConfig from '../../api-config';
import CustomAuthStorage from '../../util/CustomAuthStorage';
import { Breadcrumb, OSUFooter, ProtectedRoute } from 'osu-react-components';
import IdleTimer from 'react-idle-timer';

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

const { REACT_APP_USER_ROLE_ATTRIBUTE_KEY, REACT_APP_ALLOWED_ROLES_ALL, /* REACT_APP_ALLOWED_ROLES_RECRUITER, */ REACT_APP_ALLOWED_ROLES_ADMIN } = process.env
const userRoleAttributeKey = REACT_APP_USER_ROLE_ATTRIBUTE_KEY || ''
const allowedRolesAll = !!REACT_APP_ALLOWED_ROLES_ALL ? REACT_APP_ALLOWED_ROLES_ALL.split(",") : []
//const allowedRolesRecruiter = !!REACT_APP_ALLOWED_ROLES_RECRUITER ? REACT_APP_ALLOWED_ROLES_RECRUITER.split(",") : []
const allowedRolesAdmin = !!REACT_APP_ALLOWED_ROLES_ADMIN ? REACT_APP_ALLOWED_ROLES_ADMIN.split(",") : []

class App extends Component {
	constructor() {
		super();
		this.state = {
			screenSize: null
		}
		this.handleLoginClick = this.handleLoginClick.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		this.onIdle = this._onIdle.bind(this);
	}

	handleLoginClick = () => { this.props.setUser(); }
	handleLogoutClick = () => { Auth.signOut(); }

	componentDidMount() {
		this.updateResponsiveness();
		window.addEventListener("resize", this.updateResponsiveness.bind(this));

		if (isEmpty(this.props.user) && this.props.location.pathname !== "/signout" && this.props.location.pathname !== "/signin") {
			this.props.setUser();
		}
	}

	updateResponsiveness() {
		this.setState({
			screenSize: window.innerWidth
		})
	}

	_onIdle(e) {
		Auth.signOut();
	}

	render() {
		// console.log(process.env.REACT_APP_WHERE_AM_I);
		const { user } = this.props
		const userAttr = !!user[`${userRoleAttributeKey}`] ? intersection(allowedRolesAll, user[`${userRoleAttributeKey}`].split(",")) : ""
		const isAdmin = typeof userAttr === 'string' ? allowedRolesAdmin.includes(userAttr) : Array.isArray(userAttr) ? intersection(userAttr, allowedRolesAdmin).length > 0 : false

		return (
			<IdleTimer
				ref={ref => { this.idleTimer = ref }}
				onIdle={this.onIdle}
				timeout={60000 * 30}>
				<OSUNavBar />
				<div className="App">
					<Header admin={isAdmin} roles={userAttr} screenSize={this.state.screenSize} pathName={this.props.location.pathname} user={this.props.user} handleLogin={this.handleLoginClick} handleLogOut={this.handleLogoutClick} />
					<div className="header-shadow"></div>
					<div className="header-cover"></div>
					<main className="app-body pb-5">
						{!isEmpty(this.props.breadcrumbTrail) && <Breadcrumb className="mb-3" pathOptions={this.props.breadcrumbTrail} />}
						<Route exact path='/signin' render={() => <SignInContainer />} />
						<Route exact path='/signout' render={() => <SignOut />} />
						<ProtectedRoute path='/' screenSize={this.state.screenSize} component={PostingsListContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/postings' screenSize={this.state.screenSize} component={PostingsListContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/postings/:id' screenSize={this.state.screenSize} component={PostingItemContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/postings/:id/create-collection' admin={isAdmin} screenSize={this.state.screenSize} component={CollectionContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/postings/:id/edit-collection' screenSize={this.state.screenSize} component={SendCollectionContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/postings/:jobId/candidate/:candidateId/references/:referenceId' component={ReferenceContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAll} />
						<ProtectedRoute path='/job-profiles' screenSize={this.state.screenSize} component={JobProfilesListContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						<ProtectedRoute path='/job-profiles/:id' component={JobProfileItemContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						<ProtectedRoute path='/job-profiles/:id/questionnaire' component={JobProfileQuestionnaireContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						<ProtectedRoute path='/default-questionnaire' component={DefaultQuestionnaireContainer}
							user={user}
							id="DEFAULT"
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						<ProtectedRoute path='/questions' component={QuestionsListContainer}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						<ProtectedRoute path='/question' component={(props) => <QuestionContainer {...props} />}
							user={user}
							userIsLoading={false}
							userRoleAttributeKey={userRoleAttributeKey}
							allowedRoles={allowedRolesAdmin} />
						{/* <div>
								<h1>404 Not Found</h1>
								<div>This isn't the route you are looking for. Move Along.</div>
							</div> */}
						{/* <Route render={() => (<Redirect to="/" />)} />/*/}
					</main>
				</div>
				<OSUFooter name="HR Connection" phone="(614)-247-6947" email="HRConnection@osu.edu" />
			</IdleTimer >
		);
	}
}

export default App;
